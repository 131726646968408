import { useQuery } from 'react-query'
import API from '../services/API'

const defaultData = [{ id: '' }, { id: '' }, { id: '' }, { id: '' }]

export default function useReservation() {
  const { data, isLoading, isError, isFetching } = useQuery('filters', () => API.getReservation(), {
    staleTime: 5000 * 60,
    keepPreviousData: true,
    placeholderData: defaultData
  })

  return { data: defaultData, isLoading, isError, isFetching }
}
