import React from 'react'
import { FlexEl } from '../layout'
import ScrollOnMount from '../ScrollOnMount'
import CarCard from './../CarListing/CarCard'

function CarCardRaw({
  productHash,
  carRentalProduct,
  i,
  howManyDays,
  refreshAffirmUiOnce,
  setRefreshAffirmUiOnce,
  onBook
}) {
  const key = `${i}_${carRentalProduct.requestId}`
  // TODO: refactor
  let scrollCarFound = false

  if (carRentalProduct.productHash === productHash && !scrollCarFound && howManyDays !== 0) {
    scrollCarFound = true
    return (
      <ScrollOnMount key={key} justifyContent='center'>
        <CarCard
          refreshAffirmUiOnce={refreshAffirmUiOnce}
          setRefreshAffirmUiOnce={setRefreshAffirmUiOnce}
          carRentalProduct={carRentalProduct}
          howManyDays={howManyDays}
          onBook={onBook}
          width={1}
          mb={16}
        />
      </ScrollOnMount>
    )
  } else {
    return (
      <FlexEl width={1} key={key} justifyContent='center'>
        <CarCard
          refreshAffirmUiOnce={refreshAffirmUiOnce}
          setRefreshAffirmUiOnce={setRefreshAffirmUiOnce}
          carRentalProduct={carRentalProduct}
          howManyDays={howManyDays}
          onBook={onBook}
          width={1}
          mb={16}
        />
      </FlexEl>
    )
  }
}

export default CarCardRaw
