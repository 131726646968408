import React, { Component } from 'react'
import styled from 'styled-components'
import { El } from './layout'
import { themeGet } from 'styled-system'

const BurgerContainer = styled(El)`
  width: 50px;
  height: 50px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  * {
    margin: 0;
    padding: 0;
  }

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 30px;
    background: #2d2e2e;
    border-radius: 10px;
    opacity: 1;
    right: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    text-align: right;
  }

  span:nth-child(1) {
    top: 16px;
    width: 30px;
  }

  span:nth-child(2) {
    top: 24px;
    width: 30px;
  }

  span:nth-child(3) {
    top: 32px;
    width: 30px;
  }

  &.open span {
    background: ${themeGet('colors.textBlack')};
  }

  &.open span:nth-child(1) {
    top: 24px;
    width: 30px;
    transform: rotate(135deg);
  }

  &.open span:nth-child(2) {
    opacity: 0;
    width: 30px;
    right: 30px;
  }

  &.open span:nth-child(3) {
    top: 24px;
    width: 30px;
    transform: rotate(-135deg);
  }
`

class BurgerIcon extends Component {
  render() {
    const { isOpen, onClick, ...props } = this.props
    const className = isOpen ? 'open' : ''
    return (
      <BurgerContainer id='nav-icon1' className={className} onClick={onClick} {...props}>
        <span />
        <span />
        <span />
      </BurgerContainer>
    )
  }
}

export default BurgerIcon
