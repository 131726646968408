import React, { useState } from 'react'
import { bool, func } from 'prop-types'
import loadable from '@loadable/component'
import { El, FlexEl } from '../layout'
import { themeGet } from 'styled-system'
import styled from 'styled-components'
import { ReactComponent as ArrowDown } from '../../util/images/icon-arrow-down.svg'
import { SearchBoxForm } from './SearchBoxForm'
import { ReactComponent as PickupLogo } from '../../util/images/icons/pickupIcon.svg'
import { ReactComponent as DropoffLogo } from '../../util/images/icons/dropoffIcon.svg'
import { ReactComponent as ExtendedTrip } from '../../util/images/extended-trip.svg'
import { ReactComponent as WorkingHours } from '../../util/images/working-hours.svg'
import { ReactComponent as Airport } from '../../util/images/airport-icon.svg'
import { ReactComponent as RoundTrip } from '../../util/images/icon-roundtrip.svg'
import { ReactComponent as NearbyLocation } from '../../util/images/nearby-location.svg'

const LoadingScreen = loadable(() => import('./LoadingScreen'), {
  ssr: false,
  resolveComponent: (components) => components.LoadingScreen
})

/**
 * No car modal displayed when searchCars endpoint returns no cars.
 *
 * @param onToggleSearch function that toggles searchBox state of expanded/collapsed
 * @param searchExpanded {boolean} whether searchBox expanded or collapsed
 * @param showSearchBox {boolean} whether show search box in car-listing lor not
 * @param onSearchClicked {function} changes search with new search params when pressed search
 * @param props {props}
 * @returns {*}
 * @constructor
 */
export const NoCarModal = ({
  noCarsResponse,
  loading,
  onToggleSearch,
  searchExpanded,
  onSearchClicked,
  searchForNoCars,
  ...props
}) => {
  const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/)

  const optionsStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  const containerStyle = `
  max-width: 1120px;
  margin: auto;
  justify-content: space-between;

  @media (max-width: ${themeGet('breakpoints.1')}px) {
    flex-direction: column;
    margin: 16px 0;
    align-items: center
  }
  `

  const first = `
  min-height: 500px;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  align-items: center;
  font-size: 20px;
  color: #2C2C32;
  svg {
    max-width: 60%;
  }
  `

  const second = `
  margin-top: 16px;

  @media (min-width: ${themeGet('breakpoints.0')}px) {
    font-weight: 500;
  }
  `
  //No cars flow closed with commented lines
  return (
    <>
      {loading && <LoadingScreen />}
      {!loading && (
        /*(noCarsResponse === null || noCarsResponse.length <= 0) &&*/ <El>
          <FlexEl {...props} css={containerStyle}>
            {!isSafari && (
              <SearchBoxForm
                onToggleSearch={onToggleSearch}
                searchExpanded={searchExpanded}
                onSearchClicked={onSearchClicked}
              />
            )}
            <FlexEl css={first}>
              <svg xmlns='http://www.w3.org/2000/svg' width='430' height='264' viewBox='0 0 430 264'>
                <g fill='none' fillRule='evenodd'>
                  <path
                    fill='#B4B4C2'
                    fillRule='nonzero'
                    d='M393.23 205.517c-9.907 20.437-30.036 34.103-50.888 43.116-28.078 12.137-58.999 16.95-89.494 14.915-5.347-.353-10.675-.921-15.985-1.704-18.449-2.695-36.435-7.936-53.444-15.572-4.899-2.212-9.71-4.618-14.436-7.219-7.867-4.358-15.405-9.286-22.553-14.743-1.798-1.372-3.572-2.78-5.32-4.221-1.212-1-2.413-2.021-3.633-3.013-2.574-2.092-5.223-4.08-8.177-5.567-.891-.45-1.805-.852-2.738-1.208-9.356-3.557-20.144-2.215-29.884.56-7.4 2.091-14.611 4.98-22.023 7.043-3.292.945-6.647 1.658-10.039 2.133-7.575.964-15.27.294-22.564-1.965l-.623-.194c-1.045-.328-2.08-.687-3.108-1.078l-.622-.238c-.995-.383-1.969-.795-2.921-1.235l-.623-.283c-.995-.462-1.969-.95-2.921-1.465-.343-.18-.683-.373-1.018-.556-5.42-3.025-10.346-6.86-14.608-11.369-.123-.123-.24-.25-.373-.373-.594-.641-1.175-1.294-1.739-1.961-.209-.246-.418-.492-.623-.746-.669-.818-1.314-1.652-1.936-2.502-.093-.123-.183-.25-.272-.373-2.549-3.523-4.662-7.342-6.293-11.373-.052-.123-.1-.25-.146-.372-.497-1.28-.95-2.582-1.361-3.904-.168-.552-.328-1.12-.47-1.67-.049-.165-.093-.333-.134-.497C.22 169.784 0 161.361 0 152.986v-2.927c.06-13.546.269-27.324 2.268-40.684 0-.094.026-.187.041-.276.67-4.498 1.58-8.957 2.727-13.357 1.2-4.617 2.735-9.14 4.592-13.535 4.6-10.814 11.288-20.654 18.715-29.774 18.689-22.936 43.223-42.217 71.939-49.41 30.943-7.767 65.333-.325 90.292 19.539 7.61 6.055 14.448 13.226 22.994 17.868 7.46 4.042 16.193 5.716 24.183 2.535 6.603-2.61 10.9-3.933 18.316-3.534 15.352.865 30.487 4.032 44.898 9.392.582.213 1.164.433 1.742.653 37.568 14.374 69.869 42.918 86.648 79.598.445.97.876 1.946 1.294 2.93 10.009 23.35 13.62 50.745 2.582 73.513z'
                    opacity='.231'
                  />
                  <g fillRule='nonzero' transform='matrix(-1 0 0 1 330 84)'>
                    <path
                      fill='#8CD0F3'
                      d='M67.425 25.603S105.245 1.44 131.392 1.44c0 0 31.478-4.903 65.718 3.384 34.24 8.286 46.887 19.03 56.38 15.876l-39.881 1.168-146.184 3.735z'
                    />
                    <path
                      fill='#DFDFE7'
                      d='M67.425 25.603S105.245 1.44 131.392 1.44c0 0 31.478-4.903 65.718 3.384 34.24 8.286 46.887 19.03 56.38 15.876l-39.881 1.168-146.184 3.735z'
                    />
                    <path
                      fill='#DFDFE3'
                      d='M245.688 62.585L15.031 62.585 21.373 37.177 60.596 26.748 245.688 31.612z'
                    />
                    <path
                      fill='#B4B4C2'
                      d='M258.048 46.718v8.11s-6.848 4.967-7.47 8.857c0 0-9.806 2.49-12.606 2.49-.255 0-.532 0-.823.03.438-1.174.78-2.381 1.023-3.61 1.879-9.432-2.11-19.066-10.106-24.41-7.995-5.343-18.422-5.343-26.418 0-7.995 5.344-11.984 14.978-10.105 24.41.33 1.662.837 3.284 1.512 4.838H179.76s-.122.042-.322.13c-.713.322-2.393 1.173-2.635 2.512H66.612l-2.258-.02c.48-.805.91-1.639 1.29-2.496.708-1.588 1.234-3.251 1.568-4.958C69 53.712 65.582 44.58 58.395 39.05c-7.187-5.53-16.891-6.492-25.025-2.484-8.133 4.008-13.281 12.29-13.275 21.358 0 1.567.154 3.13.459 4.668.52 2.602 1.472 5.1 2.819 7.386-1.574-.384-5.01-1.474-5.01-1.474S3.735 66.95 0 63.666l.564-.39 2.394-1.636-1.09-4.193c-.132-.177-.24-.37-.323-.574-.29-.7-.545-2 .48-3.632 1.555-2.49.778-11.36.778-11.36s21.166-14.629 62.877-17.9c0 0 .996.774 3.054.936 1.394.113 3.274-.058 5.664-.936 0 0 3.423 1.555 18.064-6.848 0 0 24.905-17.121 57.59-15.56 0 0 33.617-2.026 62.254 9.032 12.34 4.76 21.73 7.3 28.424 8.641 6.18 1.242 10.06 1.47 11.838 1.49h1.138s0 3.706.197 8.084v.022c.071 1.523.168 3.126.303 4.684v.097c.374 4.338 1.02 8.28 2.139 9.06l1.703 4.035z'
                    />
                    <path
                      fill='#DFDFE7'
                      d='M5.948 41.515s9.377-7.316 21.05-5.758c0 0-10.622 8.406-15.408 7.861l-.816-.7-4.839.271s-.722-1.129.013-1.674zM146.494 5.066l-7.555 19.811-27.236.03h-24.36s3.638-2.671 9.5-6.074c1.935-1.13 4.131-2.336 6.515-3.548 2.193-1.123 4.554-2.258 7.035-3.336 1.935-.851 3.974-1.67 6.058-2.435 9.447-3.455 20.066-5.774 30.043-4.448zM189.291 19.533s-11.38 1.687-22.24 2.935c-4.152.477-8.226.89-11.561 1.12l-.464.031c-1.29.084-2.487.14-3.503.159-1.613.029-2.82-.042-3.423-.236l3.139-18.95s3.87.042 9.596.322l1.251.068c1.19.068 2.445.145 3.755.235 1.87.13 3.842.284 5.874.471 9.812.9 20.928 2.516 27.966 5.426 14.976 6.2-10.39 8.419-10.39 8.419zM253.98 33.606l-6.635.52v-6.081s3.074-4.32 6.148.468l.178.28.31 4.813z'
                    />
                    <circle cx='43.671' cy='57.914' r='18.989' fill='#B4B4C2' />
                    <path
                      fill='#C7C7CD'
                      d='M58.903 57.898c.014 8.137-6.35 14.86-14.477 15.288-8.126.43-15.164-5.584-16.007-13.678-.843-8.094 4.804-15.429 12.844-16.683 8.04-1.255 15.654 4.01 17.317 11.976.213 1.019.32 2.056.323 3.097z'
                    />
                    <ellipse cx='43.649' cy='57.895' fill='#DFDFE7' rx='13.093' ry='13.125' />
                    <path
                      fill='#C7C7CD'
                      d='M48.03 43.244v.555L43.67 58.227l-3.496-15.222c2.594-.604 5.301-.522 7.854.239zM47.997 72.558c-2.496.74-5.14.83-7.68.265l3.351-14.596 4.329 14.331zM58.903 57.898c.002 1.592-.244 3.174-.73 4.69h-.06L43.683 58.23l14.922-3.425c.203 1.018.302 2.054.297 3.093z'
                    />
                    <path
                      fill='#C7C7CD'
                      d='M43.671 58.227l-14.428 4.358h-.113c-.803-2.52-.946-5.203-.416-7.793l14.957 3.435z'
                    />
                    <circle cx='214.522' cy='57.914' r='18.989' fill='#B4B4C2' />
                    <path
                      fill='#C7C7CD'
                      d='M229.756 57.898c.015 8.137-6.35 14.86-14.476 15.288-8.127.43-15.164-5.584-16.007-13.678-.843-8.094 4.804-15.429 12.844-16.683 8.04-1.255 15.654 4.01 17.317 11.976.212 1.019.32 2.056.322 3.097z'
                    />
                    <ellipse cx='214.502' cy='57.895' fill='#DFDFE3' rx='13.093' ry='13.125' />
                    <path
                      fill='#C7C7CD'
                      d='M218.88 43.244v.555l-4.358 14.428-3.497-15.222c2.595-.604 5.302-.522 7.855.239zM218.85 72.558c-2.496.74-5.139.83-7.68.265l3.352-14.596 4.329 14.331z'
                    />
                    <path
                      fill='#C7C7CD'
                      d='M229.756 57.898c.002 1.592-.243 3.174-.729 4.69h-.074l-14.431-4.361 14.921-3.426c.209 1.02.314 2.057.313 3.097z'
                    />
                    <path
                      fill='#C7C7CD'
                      d='M214.522 58.227l-14.428 4.358h-.113c-.804-2.52-.947-5.203-.416-7.793l14.957 3.435z'
                    />
                    <path
                      fill='#B4B4C2'
                      d='M179.22 67.54c-.712.322-2.393 1.173-2.635 2.512H66.393l-2.258-.02c.48-.805.91-1.639 1.29-2.496l113.796.003z'
                    />
                    <path fill='#000' d='M133.06 50.411h-.114s3.71-1 .113 0z' opacity='.1' />
                  </g>
                  <g transform='translate(239 68.8)'>
                    <circle cx='73.6' cy='73.6' r='56' fill='#75757F' opacity='.3' />
                    <path
                      fill='#273C75'
                      fillRule='nonzero'
                      d='M74.533 0l.679.002c20.141.152 39.37 8.435 53.33 22.973 25.133 26.191 27.865 66.668 6.478 96.006l54.044 56.284c1.393 1.45 1.348 3.757-.1 5.153l-13.467 12.965c-1.449 1.394-3.753 1.35-5.146-.101l-54.039-56.289c-30.261 20.342-70.762 15.869-95.868-10.588C-7.232 97.238-6.752 50.731 21.543 22.169 35.72 7.842 55.07-.15 75.212.002zm38.662 37.722c-20.468-21.315-54.318-21.98-75.607-1.487C16.3 56.73 15.634 90.623 36.101 111.94c20.468 21.316 54.318 21.983 75.607 1.49 10.224-9.842 16.125-23.347 16.404-37.546.279-14.198-5.087-27.925-14.917-38.162z'
                    />
                  </g>
                </g>
              </svg>
              <FlexEl css={second}>We couldn’t find any available car</FlexEl>
              <FlexEl>Please change your search preference</FlexEl>
            </FlexEl>
          </FlexEl>
        </El>
      )}
      {/* No cars flow
      {!loading && noCarsResponse !== null && noCarsResponse.length > 0 && (
        <Options options={noCarsResponse} searchForNoCars={searchForNoCars} style={optionsStyle} {...props} />
      )} */}
    </>
  )
}

NoCarModal.propTypes = {
  onToggleSearch: func.isRequired,
  searchExpanded: bool.isRequired,
  onSearchClicked: func.isRequired
}

const Options = ({ options, searchForNoCars, ...props }) => {
  const title = `
  display: flex;
  justify-content: center;
  align-items: center;
  color: #525266;
  font-size: 18px;
  font-weight: 500;
  margin: 20px 0px;
  padding: 10px;
  `

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <span css={title}>Looks like no cars were found for this search. Let's try these alternatives:</span>
      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', columnGap: '10px' }}>
        {options.map((option, index) => {
          // TODO: fix this
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [show, setShow] = useState(false)

          return (
            <div>
              <Option key={index} option={option} show={show} setShow={setShow} searchForNoCars={searchForNoCars} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const Option = ({ option, ...props }) => {
  const { searchForNoCars, show, setShow } = props

  const determineTitle = (title) => {
    if (title === null || title === undefined) {
      return <></>
    }
    const cases = [
      title.includes('Working Hours') ? 'Stay within working hours' : null,
      title.includes('Nearby Airport Location') ? 'Try a nearby airport location' : null,
      title.includes('Extended Trip') ? 'Extend your trip for a few days' : null,
      title.includes('Search Nearby Areas') ? 'Try a nearby area' : null,
      title.includes('Round Trip') || title.includes('roundtrip') ? 'Try a round trip' : null
    ]
    return cases.find((it) => it !== null && it !== undefined)
  }

  const sanitizeSubtitle = (subtitle) => {
    if (subtitle === null || subtitle === undefined) {
      return <></>
    }
    const arr = subtitle.split('\n')

    const final = arr.filter((it) => arr.indexOf(it) < 2)

    const firstLine = final[0]
    const secondLine = final.length > 1 ? final[1] : null
    const shouldShowDropoff = secondLine !== null ? secondLine.includes('Drop') : false

    return (
      <>
        <div className={'line'}>
          <PickupLogo />
          <span>{firstLine}</span>
        </div>
        <div className={'line'}>
          <DropoffLogo style={{ visibility: shouldShowDropoff ? 'visible' : 'hidden' }} />
          <span>{secondLine}</span>
        </div>
      </>
    )
  }

  const determineLogo = (title) => {
    if (title === null || title === undefined) {
      return <></>
    }
    const cases = [
      title.includes('Working Hours') ? <WorkingHours /> : null,
      title.includes('Nearby Airport Location') ? <Airport /> : null,
      title.includes('Extended Trip') ? <ExtendedTrip /> : null,
      title.includes('Search Nearby Areas') ? <NearbyLocation /> : null,
      title.includes('Round Trip') || title.includes('roundtrip') ? <RoundTrip /> : null
    ]
    return cases.find((it) => it !== null && it !== undefined)
  }

  const actualTitle = determineTitle(option.title)
  const actualSubtitle = sanitizeSubtitle(option.subtitle)
  const logo = determineLogo(option.title)

  const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: solid 1px #dfdfe7;
    background-color: #fff;

    @media (min-width: ${themeGet('breakpoints.0')}px) {
      width: min(350px, 35vw);
    }

    @media (max-width: ${themeGet('breakpoints.0')}px) {
      width: 90vw;
      margin: 20px 0px;
    }
  `

  const header = `
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: auto 1fr;
  justify-content: center;
  align-items: center;
  margin: 15px 0px;

  > #logo {
    width: 25px;
    height: 25px;
    margin: 0 8px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > #header, #title {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }

  > #header {
    color: #ff7d0e;
  }

  > #title {
    grid-row-start: 2;
    grid-column-start: 2;
    color: #525266;
  }
  `

  const body = `
  display: flex;
  flex-direction: column;
  border-top: 1px solid #DFDFE7;
  border-bottom: 1px solid #DFDFE7;

  .line {
    display: flex;
    flex-direction: row;
    margin: 5px;
    font-weight: 500;

    > span {
      padding: 0px 10px;
    }
  }
  `

  const buttons = `
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 15px 10px;
  min-width: 100%;
  `

  const infoBtn = `
  display: flex;
  flex-direction: row;
  background-color: #f2f2f2;
  justify-content: center;
  border: none;
  border-radius: 4px;
  align-items: center;
  width: 40%;

  &:hover {
    cursor: pointer;
  }

  > span {
    font-weight: bold;
    color: #525266;
    font-size: 14px;
    padding-left: 3px;
  }
  `

  const searchBtn = `
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  width: 60%;
  height: 28px;
  border-radius: 4px;
  background-image: radial-gradient(circle at 2% 54%, #fe9661, #ff7d0e 47%, #ffb369 90%);
  color: white;

  &:hover {
    cursor: pointer;
  }
  `

  const infoStyle = {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '0px 10px 10px 10px',
    display: show ? 'block' : 'none',
    objectFit: 'contain'
  }

  const handleClick = () => {
    searchForNoCars(option.recommendedCarSearchArgs)
  }

  return (
    <Container>
      <div css={header}>
        <span id={'logo'}>{logo}</span>
        <span id={'header'}>Alternative Search</span>
        <span id={'title'}>{actualTitle}</span>
      </div>
      <div css={body}>{actualSubtitle}</div>
      <div css={buttons}>
        <div css={infoBtn} onClick={() => setShow(!show)}>
          <ArrowDown />
          <span>more info</span>
        </div>
        <div css={searchBtn} onClick={handleClick}>
          Search Now
        </div>
      </div>
      <div style={infoStyle}>{option.additionalInfo}</div>
    </Container>
  )
}
