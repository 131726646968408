import { Component } from 'react'
import * as ReactDOM from 'react-dom'

// make element scroll into view after mounting
class ScrollOnMount extends Component {
  componentDidMount() {
    const $this = ReactDOM.findDOMNode(this)
    $this.scrollIntoView()
    window.scrollBy(0, -100) // pass navigation bar
  }

  render() {
    return this.props.children
  }
}

export default ScrollOnMount
