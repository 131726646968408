import React from 'react'
import styled from 'styled-components'
import { ReactComponent as CarRentalText } from '../util/images/car-rental-text.svg'
import { ReactComponent as CarlaWhiteLogo } from '../util/images/carla-logo-white.svg'
import { useAuth0 } from '@auth0/auth0-react'
import { Redirect } from 'react-router-dom'

const LoginPageContainer = styled('div')`
  background-color: white;
  display: flex;
  height: calc(100vh - 80px);
  height: calc(100dvh - 80px);
  width: 100%;
  justify-content: space-evenly;
`
const Background = styled('div')`
  background-color: white;
  background-image: url(${'/images/portal-bg.png'});
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12%;
  background-repeat: no-repeat;
  background-size: cover;
`
const LoginButton = styled('button')`
  border-radius: 8px;
  border: 1px solid #ff7d0e;
  background: var(--actions-primary, #ff7d0e);
  color: var(--background-white, #fff);
  text-align: center;
  font-family: Quicksand;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 180% */
  padding: 4px 25px;
  display: block;
  outline: none;
  margin-top: 40px;
  height: 64px;
  width: 240px;
`

function Login() {
  const { loginWithRedirect, isAuthenticated } = useAuth0()

  if (isAuthenticated) return <Redirect path='/' />

  return (
    <LoginPageContainer>
      <Background>
        <CarlaWhiteLogo style={{ marginBottom: '16px' }} />
        <CarRentalText />
        <LoginButton onClick={() => loginWithRedirect()}>Login</LoginButton>
      </Background>
    </LoginPageContainer>
  )
}

export default Login
