import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  nearMePicked: false,
  nearMeCoords: {
    lat: null,
    lng: null
  }
}

// TODO: merge from setNearMePicked and nearMeCoords
export const nearMePickedSlice = createSlice({
  name: 'nearMePickedSlice',
  initialState,
  reducers: {
    setNearMePicked: (state, action) => {
      state.nearMePicked = action.payload
    },
    setNearMeLatitude: (state, action) => {
      state.nearMeCoords.lat = action.payload
    },
    setNearMeLongitude: (state, action) => {
      state.nearMeCoords.lng = action.payload
    }
  }
})

export const { setNearMePicked, setNearMeLatitude, setNearMeLongitude } = nearMePickedSlice.actions

export default nearMePickedSlice.reducer
