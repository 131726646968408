import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const filterTagsSlice = createSlice({
  name: 'filterTags',
  initialState,
  reducers: {
    setTagFilters: (state, action) => {
      state = action.payload
      return state
    }
  }
})

export const { setTagFilters } = filterTagsSlice.actions

export default filterTagsSlice.reducer
