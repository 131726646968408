import React from 'react'
import { El, FlexEl } from '../../layout'
import { GroupTitle, TextInput, IconStyled, PickUpLocationWrapper, ImageStyled } from './TravelerFiled.styles'
import AgeIcon from '../../../util/images/driver-s-license.svg'

function TravelersFiled({
  label,
  type = 'text',
  value,
  onChange,
  readonly,
  placeholder,
  leftRadius = 0,
  rightRadius = 0
}) {
  const handleOnchange = (event) => {
    if (event.currentTarget && event.currentTarget.value && onChange) {
      onChange(event.currentTarget.value)
    }
  }

  return (
    <FlexEl flexDirection='column' width={1}>
      <El width={1}>
        <GroupTitle>{label}</GroupTitle>
        <PickUpLocationWrapper>
          {readonly ? <IconStyled /> : <ImageStyled src={AgeIcon} alt='icon' />}
          <TextInput
            type={type}
            onChange={handleOnchange}
            placeholder={placeholder}
            value={value}
            disabled={readonly}
            $leftRadius={leftRadius}
            $rightRadius={rightRadius}
          />
        </PickUpLocationWrapper>
      </El>
    </FlexEl>
  )
}

export default TravelersFiled
