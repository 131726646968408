import React, { Component } from 'react'
import styled from 'styled-components'
import Downshift from 'downshift'
import { MenuDS, SelectContainerDS } from '../../CarlaSelect'
import { El, FlexEl, SearchButton } from '../../layout'
import util from '../../../util'
import DateRangePicker from '../../DatePicker/HotelDateRangePicker'
import CalendarIcon from '../../../util/images/icon-calendar.svg'
import {
  IconStyled,
  DateInputWrapper,
  InputArea,
  GroupTitle,
  BottomButtonWrapper,
  SubmitButton
} from './DateField.styles'
import TimeSlider from '../../../components/SearchForm/DesktopForm/TimeSlider'
import getTime from 'date-fns/getTime'

const defaultTime = {
  hour: 12,
  min: 0
}

const DateField = styled(
  class extends Component {
    render() {
      const {
        mobile,
        showOnMobile,
        setShowOnMobile,
        isCarSearchPage,
        locationChange,
        updateHotelSearchDateRange,
        label,
        startDate,
        endDate,
        hasTime = false,
        onChangeDateRange = () => {},
        setSearchPickupTime,
        setSearchDropOffTime,
        pickupTime = defaultTime,
        dropOffTime = defaultTime,
        ...rest
      } = this.props

      let pickupDateTime = null
      if (startDate) {
        pickupDateTime = getTime(new Date(startDate).setHours(pickupTime.hour, pickupTime.min))
      }

      let dropOffDateTime = null
      if (endDate) {
        dropOffDateTime = getTime(new Date(endDate).setHours(dropOffTime.hour, dropOffTime.min))
      }

      const downshiftStateReducer = (state, changes) => {
        if (changes.type === Downshift.stateChangeTypes.clickButton) {
          isCarSearchPage && setShowOnMobile(true)
          return {
            ...changes,
            isOpen: true
          }
        }
        isCarSearchPage && locationChange(true)
        return changes
      }

      let inputValue = 'Select date'
      if (pickupDateTime && dropOffDateTime) {
        inputValue = hasTime
          ? util.formatDateTimeRange(pickupDateTime, dropOffDateTime)
          : util.formatDateRangeWithoutTime(pickupDateTime, dropOffDateTime)
      }

      return (
        <Downshift stateReducer={downshiftStateReducer} {...rest}>
          {({ isOpen, getRootProps, getLabelProps, getInputProps, getMenuProps, getToggleButtonProps }) => {
            return (
              <SelectContainerDS {...getRootProps()} {...rest}>
                {/* DATE RANGE SELECT */}
                <El width={1} className='form-field'>
                  <GroupTitle {...getLabelProps()}>{label}</GroupTitle>
                  <DateInputWrapper isDateInputSelected={isOpen}>
                    <IconStyled src={CalendarIcon} alt={'rent_carla_calendar_icon'} />
                    <InputArea
                      type='button'
                      isDateInputSelected={isOpen}
                      {...getInputProps({
                        value: inputValue,
                        placeholder: 'When'
                      })}
                      {...getToggleButtonProps()}
                    />
                  </DateInputWrapper>
                </El>
                {/* DATE RANGE CALENDAR POPUP */}
                <FlexEl width={[1, 1, 550]} style={{ position: 'absolute' }}>
                  <MenuDS
                    style={{
                      maxHeight: mobile && '64vh',
                      zIndex: mobile && 1000,
                      boxShadow: mobile && '0px 4px 4px 0px rgba(0, 0, 0, 0.12)',
                      overflow: 'hidden',
                      marginTop: '0',
                      width: 'auto',
                      maxWidth: '100%'
                    }}
                    isOpen={mobile ? showOnMobile : isOpen}
                    {...getMenuProps()}
                    id='daterange-selectbox'
                  >
                    {/* //TODO: required and range need to be pass down */}
                    <DateRangePicker
                      startDate={startDate}
                      endDate={endDate}
                      onChange={onChangeDateRange}
                      hasTime={hasTime}
                      required
                      mode='range'
                    />
                    {hasTime && (
                      <>
                        <TimeSlider label='Pickup time' value={pickupTime} onChange={setSearchPickupTime} />
                        <TimeSlider label='Drop off time' value={dropOffTime} onChange={setSearchDropOffTime} />
                      </>
                    )}

                    {mobile && (
                      <BottomButtonWrapper>
                        <SubmitButton onClick={() => setShowOnMobile(false)}>Apply</SubmitButton>
                      </BottomButtonWrapper>
                    )}
                  </MenuDS>
                </FlexEl>
              </SelectContainerDS>
            )
          }}
        </Downshift>
      )
    }
  }
)`
  #daterange-selectbox {
    max-height: unset;
    ${SearchButton} {
      font-size: 16px;
      padding: 10px 40px;
    }
  }
  ${MenuDS} {
    width: 100%;
    text-align: center;
  }
`

export default DateField
