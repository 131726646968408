import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { El, FlexEl } from '../../layout'
import {
  updateSearchDateRange,
  setSearchAge,
  setSearchPickupTime,
  setSearchDropOffTime,
  toggleSameLocation,
  toggleUSDevice
} from '../../../redux/slices'
import LocationField from '../../SearchBoxV3/Fields/LocationField'
import TravelerFiled from '../../SearchBoxV3/Fields/TravelerFiled'
import DateField from './../Fields/DateField'
import { CarSearchContainer } from './CarSearch.styled'
import CheckBox from './../../../components/CheckBox/CheckBox'
import ReactLoading from 'react-loading'
import usePopularLocations from './../../../hooks/usePopularLocations'

const SearchButton = styled('button')`
  position: relative;
  font-size: 18px;
  font-weight: 600;
  border-radius: 0px 12px 12px 0px;
  background: var(--actions-primary, #ff7d0e);
  border: 1px solid var(--colorPrimary, #ff7d0e);
  color: #fff;
  padding: 16px 16px;
  cursor: pointer;
  width: 100%;
`

const ButtonWrapper = styled(El)`
  z-index: 999;
  align-items: flex-end;
  display: flex;
`

const SearchBarWrapperDesktop = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  color: var(--textBlack, #525266);
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0;
  margin: 16px 0 0;
`

const SearchBarDesktop = ({ handleSubmit }) => {
  const dispatch = useDispatch()
  const { 
    pickupDateStr,
    dropOffDateStr,
    age = 26,
    isSameLocation,
    pickupTime,
    dropOffTime
  } = useSelector((state) => state.searchArgs)
  const isUSDevice = useSelector((state) => state.user.isUSDevice);
  // Keep old design
  const [isLoading, setIsLoading] = useState(false)
  const [isNewSearch, setIsNewSearch] = useState(false)
  const { data: popularLocations } = usePopularLocations()

  const setCarBookingRange = (newValue) => dispatch(updateSearchDateRange(newValue))
  const onToggleSameLocation = () => dispatch(toggleSameLocation())
  const onToggleUSDevice = () => dispatch(toggleUSDevice())

  // Automatically select US device when the component mounts
  useEffect(() => {
    if (!isUSDevice) {
      onToggleUSDevice()
    }
  }, [])

  const onSubmit = (values) => {
    setIsNewSearch(true)
    setIsLoading(true)
    handleSubmit(values)
  }

  return (
    <SearchBarWrapperDesktop>
      <CarSearchContainer>
        <FlexEl show={[0, 1, 1]} width={[1, 1, 1120]} as='form' onSubmit={onSubmit}>
          <FlexEl width={[1, 1, 0.4]} mb={[25, 25, 0]} className='form-field-container'>
            <LocationField
              leftRadius={12}
              type={'pickUp'}
              label={isSameLocation ? 'Pick up & drop off location' : 'Pick up location'}
              popularLocations={popularLocations}
            />
          </FlexEl>
          <FlexEl width={[1, 1, 0.35]} mb={[25, 25, 0]} className='form-field-container'>
            <DateField
              label='Pick up and drop off time'
              startDate={new Date(pickupDateStr)}
              endDate={new Date(dropOffDateStr)}
              onChangeDateRange={setCarBookingRange}
              hasTime
              setSearchPickupTime={(time) => dispatch(setSearchPickupTime(time))}
              setSearchDropOffTime={(time) => dispatch(setSearchDropOffTime(time))}
              pickupTime={pickupTime}
              dropOffTime={dropOffTime}
            />
          </FlexEl>
          <El width={[1, 1, 0.1]} mb={[25, 25, 0]} className='form-field-container'>
            <TravelerFiled
              label={'Driver’s age'}
              value={age}
              onChange={(age) => dispatch(setSearchAge(age))}
              placeholder={26}
              type='text'
            />
          </El>
          <ButtonWrapper width={[1, 1, 0.15]}>
            <SearchButton type='submit'>
              {isLoading ? (
                <ReactLoading className='loading-animation' type={'spin'} height={22} width={22} />
              ) : (
                <span>Search Cars</span>
              )}
            </SearchButton>
          </ButtonWrapper>
        </FlexEl>
        {!isSameLocation && (
          <FlexEl width={[1, 1, 0.4]} mt={8}>
            <LocationField
              leftRadius={12}
              rightRadius={12}
              type={'dropOff'}
              label='Drop off location'
              popularLocations={popularLocations}
            />
          </FlexEl>
        )}
        <FlexEl width={[1, 1, 0.4]} mb={8}>
          <CheckBox isChecked={isSameLocation} onChange={onToggleSameLocation} label='Drop off at the same location' />
        </FlexEl>
        <FlexEl width={[1, 1, 0.4]} mb={8}>
          <CheckBox isChecked={isUSDevice} onChange={onToggleUSDevice} label='US Device' />
        </FlexEl>
      </CarSearchContainer>
    </SearchBarWrapperDesktop>
  )
}
export default SearchBarDesktop
