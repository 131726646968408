import Menu from 'react-burger-menu/lib/menus/slide'
import { themeGet } from 'styled-system'
import { El, FlexEl, LinkEl } from '../components/layout'
import styled from 'styled-components'
import { theme } from '../styles'

export const LogoLink = styled(LinkEl)`
  min-width: 70px;
  width: 70px;
  background-color: ${themeGet('colors.none')};
  path {
    fill: ${themeGet('colors.colorPrimary')};
  }
`

export const NavbarContainer = styled(El)`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000 !important;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: white;

  .active::after {
    height: 11px;
    display: block;
    opacity: 1;
    width: 50%;
    position: absolute;
    content: '';
    transition: opacity 0.1s linear, width 0.3s;
    border-bottom: 8px solid ${themeGet('colors.colorPrimary')};
  }

  &.fixed-header {
    background: #fff !important;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    a {
      color: var(--textBlack) !important;
    }
    #nav-icon1 span {
      background: ${themeGet('colors.textBlack')};
    }
  }
  &.home-header {
    box-shadow: none !important;
    background: #ffffff;
    a {
      color: #525266;
    }
  }
`

export const MobileNavBarContainer = styled(El)`
  .active::after {
    height: 11px;
    display: block;
    opacity: 1;
    width: 50%;
    position: absolute;
    content: '';
    transition: opacity 0.1s linear, width 0.3s;
    border-bottom: 8px solid ${themeGet('colors.colorPrimary')};

    @media (min-width: ${themeGet('breakpoints.0')}px) {
      display: none;
    }
  }
`

export const NavLink = styled(LinkEl)`
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  outline-style: none;
  text-decoration: none;
  color: ${themeGet('colors.textBlack')};
  position: relative;
  transform: transition 0.1s ease-in-out;

  &::after {
    display: block;
    content: '';
    width: 0px;
    opacity: 0;
    transition: opacity 0.1s linear, width 0.3s;
  }

  &:hover::after {
    height: 11px;
    display: block;
    opacity: 1;
    width: 100%;
    position: absolute;
    content: '';
    transition: opacity 0.1s linear, width 0.3s;
    border-bottom: 8px solid ${themeGet('colors.colorPrimary')};
  }

  &:focus::after {
    height: 11px;
    display: block;
    opacity: 1;
    width: 50%;
    position: absolute;
    content: '';
    transition: opacity 0.1s linear, width 0.3s;
    border-bottom: 8px solid ${themeGet('colors.colorPrimary')};
  }
  @media (min-width: ${themeGet('breakpoints.0')}px) {
    font-size: 16px;
  }
`

export const Buttons = styled(LinkEl)`
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  outline-style: none;
  text-decoration: none;
  color: ${themeGet('colors.textBlack')};
  max-height: 55px;
  position: relative;
  transform: transition 0.1s ease-in-out;

  &::after {
    display: block;
    content: '';
    width: 0px;
    opacity: 0;
    transition: opacity 0.1s linear, width 0.3s;
  }
`
export const OutlineButton = styled(NavLink)`
  display: flex;
  align-items: center;
  position: relative;
  height: 55px;
  border-radius: 12px;
  font-weight: bold;
  &:hover:after {
    display: none;
  }
  &:focus:after {
    display: none;
  }
`

export const SimpleButton = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 55px;
  font-weight: bold;
`

export const StyledMenu = styled(Menu)`
  width: 100%;
  margin-top: ${theme.navbar.height[0]}px;
  padding-top: 10vh;
  background-color: white;
  text-align: center;
  font-size: 30px;
  position: relative;
  z-index: 1000 !important;

  &::before {
    position: absolute;
    top: -10vh;
    left: 0px;
    content: '';
    width: 100%;
    height: 10vh;
    background: white;
  }

  @media (min-width: ${themeGet('breakpoints.1')}px) {
    display: none;
  }
`

export const NavbarWrapper = styled(FlexEl)`
  position: relative;
  z-index: 1000 !important;
  @media only screen and (max-width: ${themeGet('breakpoints.0')}px) {
    width: 100vw;
    padding-left: calc(46vw - 37.5px);
    padding-top: 5px;
    padding-bottom: 5px;
  }
  @media only screen and (min-width: ${themeGet('breakpoints.0')}px and max-width: ${themeGet('breakpoints.1')}px) {
    width: 100vw;
    padding-left: 0;
  }
`

export const NavTagWrapper = styled(FlexEl).attrs({
  as: 'nav'
})``

export const UlTagWrapper = styled(FlexEl).attrs({
  as: 'ul'
})`
  list-style: none;
  gap: 80px;
`

export const MobileUlTagWrapper = styled(MobileNavBarContainer).attrs({
  as: 'ul'
})`
  list-style: none;
`
