import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const CheckBoxWrapper = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  width: 90%;
  margin: 10px auto 0;
  @media (min-width: ${themeGet('breakpoints.0')}px) {
    width: 90%;
    margin: 10px auto 0;
  }
  @media (min-width: ${themeGet('breakpoints.1')}px) {
    width: 100%;
    margin: 10px auto 0;
  }
`

export const CheckBoxUI = styled.div`
  height: 24px;
  width: 24px;
  background-color: white;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  @media (min-width: ${themeGet('breakpoints.0')}px) {
    height: 30px;
    width: 30px;
  }
`

export const CheckBoxImg = styled.img`
  height: 12px;
  width: auto;
  display: inline-block;
  margin-left: 4px;
  margin-top: 6px;
  @media (min-width: ${themeGet('breakpoints.0')}px) {
    height: 18px;
    margin-left: 3px;
    margin-top: 6px;
  }
`
export const SameLocationText = styled.div`
  display: inline;
  margin-left: 10px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: default;
  @media (min-width: ${themeGet('breakpoints.0')}px) {
    font-size: 18px;
    font-weight: 500;
  }
`
