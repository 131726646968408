import debug from 'debug'
import API from './API'
import util from '../util'
import { store as reduxStore } from '../redux/store'
import { setAuthKey, setCarlinDeviceId, setDeviceId, setSharedSecret, setUserCountry } from '../redux/slices'
import EventSender from './EventSender'
import hmacSHA512 from 'crypto-js/hmac-sha512'
import encHex from 'crypto-js/enc-hex'
import axios from 'axios'
import isEqual from 'lodash/isEqual'

const log = debug('carla:user')

const USDevice = {
  deviceId: 16758291,
  sharedSecret: 'iPGefyDLTuLLyiyDzWX09y15GqbO1uqz4aXnzHfizXOqa1vbbqH1KvTGiiSz1KX9XqCLLLeP1yqaqfnjfjW8XS8WyDbLyzHmvPbXCvzSyniai9rXjHXHbe1nmySrOO5GOa5P0faSaXn5fTj4W8KGnTeHSHqa1efXGuOyejyiC1eqKqzW18qjuu58POGiuX1Dn0LSTjLG',
  country: 'US'
};

const nonUSDevice = {
  deviceId: '16758356',
  sharedSecret: 'jereD049GLrTLD91ymSSPK9X8XHnKrDa4508umSafL41eP8frmKbbLGueyP1eWuWGmnu8WC99eXHuiGe9vC4Kmb89T58qPznzjXrWebLar8OOnTbrqiu9e9j5rb9LerevquuDGu59jG0O9XT4W4rWqXL1XeunGK1m0ibvnSi9nqCaDqv94HmSe9L9fSOLj10XyyivOe0',
  country: 'IT'
};

const SessionManager = {
  getDeviceId() {
    return reduxStore.getState().user.deviceId
  },

  getIsUSDevice() {
    return reduxStore.getState().user.isUSDevice
  },

  getCarlinDeviceId() {
    return this.getIsUSDevice() ? USDevice.deviceId : nonUSDevice.deviceId
  },

  getAuthKey() {
    return this.fetchAuthKey()
  },

  getSharedSecret() {
    return this.getIsUSDevice() ? USDevice.sharedSecret : nonUSDevice.sharedSecret
  },

  /**
   * checks property and cookies for user id, generates new one  if not found
   * @returns {Promise<string>}
   */

  getRegion: function () {
    return reduxStore.getState().user.country
  },

  getUserCountry() {
    return this.getIsUSDevice() ? USDevice.country : nonUSDevice.country
  },

  fetchAuthKey() {
    const isUSDevice = this.getIsUSDevice();
    if (isUSDevice) {
      return hmacSHA512(USDevice.deviceId.toString(), USDevice.sharedSecret).toString(encHex)
    } else {
      return hmacSHA512(nonUSDevice.deviceId.toString(), nonUSDevice.sharedSecret).toString(encHex)
    }
  },

  async generateDeviceIdForDeepLink(onError) {
    let authKey = this.getAuthKey()
    if (authKey) {
      log(`device id found in state: ${authKey}`)
      return
    }
    log('generating new auth key')
    if (!this.getAuthKey()) {
      try {
        await this.fetchAuthKey()
      } catch (e) {
        onError(e)
      }
    }
    log(`new device id generated: "${this.getAuthKey()}"`)
  },

  async fetchUserCountry() {
    const userCountryCode = this.getUserCountry()
    const isValid = this.getWithExpiry('countryCodeExpiry')
    if (userCountryCode && isValid) {
      return userCountryCode
    } else {
      axios
        .get('https://ipapi.co/json/')
        .then((response) => {
          const countryCode = response.data.country_code
          this.setUserCountry(countryCode)
          return countryCode
        })
        .catch((error) => {
          // TODO: sent to sentry
          this.setUserCountry('US')
          return 'US'
        })
    }
  },

  setDeviceId(deviceId) {
    reduxStore.dispatch(setDeviceId(deviceId))
  },

  setUserCountry(country) {
    reduxStore.dispatch(setUserCountry("US"))
  },

  setSharedSecret(sharedSecret) {
    reduxStore.dispatch(setSharedSecret(sharedSecret))
  },

  setAuthKey(authKey) {
    reduxStore.dispatch(setAuthKey(authKey))
  },

  setCarlinDeviceId(carlinDeviceId) {
    reduxStore.dispatch(setCarlinDeviceId(carlinDeviceId))
  },

  getSessionId() {
    return util.getFromLocalStorage('session-id') || 0
  },

  /**
   * @param searchArgs {{pickupLocationId, dropOffLocationId, pickupDateStr, dropOffDateStr, age}}
   * @returns {null|object}
   */
  getLastCarRentalProducts(searchArgs) {
    const lastCarSearchInfo = util.getFromLocalStorage('carla-last-car-search-info')
    const lastCarSearchTS = util.getFromLocalStorage('carla-last-car-search-ts')
    const nowTS = new Date().getTime()

    if (lastCarSearchInfo) {
      lastCarSearchInfo.age = String(lastCarSearchInfo.age)
      searchArgs.age = String(searchArgs.age)
    }

    if (!isEqual(searchArgs, lastCarSearchInfo)) return null // if search info is changed
    if (nowTS - lastCarSearchTS > 1000 * 60 * 5) return null // cache limit is 5 mins
    return {
      carCached: util.getFromLocalStorage('carla-last-car-search-results'),
      adsCached: util.getFromLocalStorage('carla-last-ads-search-results')
    }
  },
  saveLastCarRentalProducts(searchArgs, carResult) {
    const ts = new Date().getTime()
    util.saveToLocalStorage('carla-last-car-search-results', carResult)
    util.saveToLocalStorage('carla-last-car-search-info', searchArgs)
    util.saveToLocalStorage('carla-last-car-search-ts', ts)
  },

  saveLastAdsRentalProducts(adsResult) {
    util.saveToLocalStorage('carla-last-ads-search-results', adsResult)
  },

  // get last saved state from local storage
  getSavedState() {
    const state = util.getFromLocalStorage('state')
    if (state === null) {
      return undefined
    }
    return state
  },

  saveState(state) {
    util.saveToLocalStorage('state', state)
  },

  saveWithExpiry(key, value, ttl) {
    util.saveToLocalStorageWithExpiry(key, value, ttl)
  },

  getWithExpiry(key) {
    return util.getFromLocalStorageWithExpiry(key)
  },

  async startSession() {
    // to check if session is already count
    if (!util.getFromSessionStorage('session-started')) {
      util.saveToSessionStorage('session-started', true)
      let sessionId = util.getFromLocalStorage('session-id')
      if (sessionId === null) sessionId = -1

      const newSessionId = Number(sessionId) + 1
      log('starting new session, current: ', newSessionId)
      util.saveToLocalStorage('session-id', newSessionId)
      EventSender.sessionStarted()
    }

    return await Promise.all([this.fetchAuthKey()])
  }
}

export default SessionManager
