import React from 'react'
import styled from 'styled-components'
import SupplierImg from '../../util/images/supplier-collage@3x.webp'
import SearchBarMobile from './SearchBarMobile'
import { MobileScreen, TabletScreen } from './../common/Screens'
import SupplierImgDesktop from '../../util/images/supplier-collage-desktop.webp'

const SearchBarWrapperMobile = styled.div`
  width: 90%;
  padding-bottom: 20px;
  padding-top: 10px;
  margin: 0 auto;
  max-width: 700px;
  border-radius: 12px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: rgba(82, 82, 102, 0.45);
`

const SupplierImageTablet = styled.img`
  position: absolute;
  right: 5vw;
  top: 700px;
  height: 220px;
`

const SupplierImageMobile = styled.img`
  position: absolute;
  height: 180px;
  width: auto;
  top: 780px;
  margin: 0 auto;
`

export default function MobileSearch({ handleSubmit }) {
  return (
    <>
      <SearchBarWrapperMobile>
        <SearchBarMobile onSubmit={handleSubmit} />
      </SearchBarWrapperMobile>
      <TabletScreen>
        <SupplierImageTablet src={SupplierImgDesktop} />
      </TabletScreen>
      <MobileScreen>
        <SupplierImageMobile src={SupplierImg} />
      </MobileScreen>
    </>
  )
}
