import React, { Component, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import API from '../../services/API'
import { DesktopScreen, DefaultScreen } from './../../components/common/Screens'
import { withRouter } from 'react-router-dom'
import Search from '../../components/SearchBoxV3/CarSearch'

export const HomeSearchWrap = styled.div`
  border-radius: 12px;
  background: rgba(82, 82, 102, 0.4);
  backdrop-filter: blur(4px);
  padding: 16px 24px;
  label {
    color: #fff;
  }
`

const HeaderTitle = styled.h1`
  margin: 0;
  color: #fff;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  color: #fffdf1;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
`
const SearchBarContainer = styled.div`
  padding-top: 10%;
  width: 100%;
  height: calc(100vh - 80px);
  height: calc(100dvh - 80px);
  align-items: center;
  justify-content: center;
  background-image: url(${'/images/portal-bg.png'});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  z-index: 1;
`

class Home extends Component {
  state = {
    subjects: null
  }

  scrollFunction() {
    const navbarHomePage = document.getElementById('NAVBAR-HOMEPAGE')
    if (!navbarHomePage) return

    if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
      navbarHomePage.classList.add('fixed-header')
      navbarHomePage.classList.remove('home-header')
    } else {
      navbarHomePage.style.background = `rgba(255,255,255,${document.documentElement.scrollTop / 30})`
      if (document.documentElement.scrollTop === 0) {
        navbarHomePage.classList.remove('fixed-header')
        navbarHomePage.classList.add('home-header')
      } else {
        navbarHomePage.classList.add('fixed-header')
        navbarHomePage.classList.remove('home-header')
      }
    }
  }

  componentDidMount() {
    if (!this.state.subjects) {
      API.getFAQs('?key=new.support.1.t').then((data) => this.setState({ subjects: data }))
    }

    const navbarHomePage = document.getElementById('NAVBAR-HOMEPAGE')
    if (navbarHomePage) {
      navbarHomePage.classList.add('home-header')
      window.addEventListener('scroll', this.scrollFunction)
    }
  }

  handleAnchor(e) {
    e.preventDefault()

    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  componentDidUpdate() {
    const Anchor = document.getElementById('SEECARRENTALPRICES')
    if (Anchor) Anchor.addEventListener('click', this.handleAnchor)

    const navbarHomePage = document.getElementById('NAVBAR-HOMEPAGE')
    if (navbarHomePage && document.documentElement.scrollTop === 0 && window.scrollY !== 0) {
      navbarHomePage.style.background = 'none'
      navbarHomePage.style.boxShadow = 'none'
      window.addEventListener('scroll', this.scrollFunction)
    }
  }

  componentWillUnmount() {
    const navbarHomePage = document.getElementById('NAVBAR-HOMEPAGE')
    if (navbarHomePage) {
      navbarHomePage.style.background = 'white'
      navbarHomePage.classList.remove('home-header')
      navbarHomePage.classList.remove('fixed-header')
    }

    window.removeEventListener('scroll', this.scrollFunction)
  }

  render() {
    const { history } = this.props

    return (
      <Fragment>
        <Helmet>
          <title>Carla - Rent a Car Anywhere, Anytime in 30 seconds.</title>
          <link rel='canonical' href='https://rentcarla.com/' />
        </Helmet>
        <SearchBarContainer>
          <DesktopScreen>
            <HomeSearchWrap>
              <HeaderTitle> Car Rental </HeaderTitle>
              <Search history={history} />
            </HomeSearchWrap>
          </DesktopScreen>
          <DefaultScreen>
            <Search history={history} />
          </DefaultScreen>
        </SearchBarContainer>
      </Fragment>
    )
  }
}

export default withRouter(Home)
