import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  country: null,
  deviceId: null,
  carlinDeviceId: null,
  language: 'en',
  sharedSecret: null,
  authKey: null,
  isUSDevice: true
}

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUserCountry: (state, action) => {
      state.country = action.payload
    },
    setSharedSecret: (state, action) => {
      state.sharedSecret = action.payload
    },
    setDeviceId: (state, action) => {
      state.deviceId = action.payload
    },
    setAuthKey: (state, action) => {
      state.authKey = action.payload
    },
    setCarlinDeviceId: (state, action) => {
      state.carlinDeviceId = action.payload
    },
    setUSDevice: (state, action) => {
      state.isUSDevice = action.payload
      return state
    },
    toggleUSDevice: (state, action) => {
      state.isUSDevice = !state.isUSDevice
      return state
    }
  }
})

export const {
  setUserCountry,
  setSharedSecret,
  setDeviceId,
  setAuthKey,
  setCarlinDeviceId,
  setUSDevice,
  toggleUSDevice
} = userSlice.actions

export default userSlice.reducer
