import React from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { CardFlexEl, El, FlexEl } from '../layout'
import VerticalDash from '../../util/images/pickup-dropoff-line@3x.webp'

export const RefWrapper = styled('div')`
  width: 100%;
`

export const Info = styled(({ icon, description, ...props }) => (
  <FlexEl {...props}>
    <El className='icon'>{icon}</El>
    <El className='description'>{description}</El>
  </FlexEl>
))`
  .icon {
    margin-right: 3px;
    @media (min-width: ${themeGet('breakpoints.0')}px) {
      margin-right: 3px;
    }
  }
  .description {
    color: ${themeGet('colors.textBlack')};
    /* font-size: 15px; */
    font-weight: normal;
    padding-bottom: 4px;
    margin-top: auto;
    margin-bottom: auto;
    @media (min-width: ${themeGet('breakpoints.0')}px) {
      font-size: 14px;
    }
  }
`

export const BookButton = styled(Info)`
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  .description {
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 0;
  }
`

export const OuterContainer = styled(CardFlexEl)`
  max-width: 784px;
  flex-direction: column;
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  color: #525266;
  padding: 0;
  margin-bottom: 16px;

  @media (max-width: ${themeGet('breakpoints.0')}px) {
    width: 90vw;
    margin: 0 auto 16px;

    > .book-button {
      width: 50%;
    }
  }

  .book-button {
    background-image: radial-gradient(circle at 2% 54%, #fe9661, #ff7d0e 47%, #ffb369 90%);
    min-width: 175px;
    margin-top: auto;
    margin-left: auto;
    margin-right: 16px;
    margin-bottom: 16px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 8px;
    min-height: 44px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    a {
      color: white;
      text-decoration: none;
      width: 100%;
      margin: 0 auto;
      padding: 14px;
    }
  }

  @media (min-width: ${themeGet('breakpoints.0')}px) {
    padding: 0;
  }
`

export const InnerContainer = styled(FlexEl)`
  display: flex;
  align-items: center;
  margin: 0;

  @media (max-width: ${themeGet('breakpoints.0')}px) {
    flex-direction: column;
  }
  @media (min-width: ${themeGet('breakpoints.0')}px) {
    flex-direction: row;
    padding: 0;
    position: relative;
  }
`

export const ImageContainer = styled(FlexEl)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: none;
  width: 30%;
  padding: 5px;
  margin-top: 2%;

  img {
    margin: 5px;
    align-items: center;
    object-fit: contain;
  }

  .supplier-img {
    max-width: 50%;
    max-height: 40px;
  }

  .highlight-mobile {
    display: none;
  }

  .car-img {
    max-height: 90px;
    min-height: 90px;
  }

  @media (max-width: ${themeGet('breakpoints.0')}px) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    padding: 0px;

    .supplier-img {
      grid-row-start: 1;
      grid-column-start: 1;
    }

    .highlight-mobile {
      display: flex;
    }

    .car-img {
      grid-column: span 2;
      justify-self: center;
      grid-row-start: 2;
    }
  }
`

export const InfoFirst = styled(FlexEl)`
  flex-direction: column;
  justify-content: center;
  align-self: flex-end;
  width: 45%;
  padding: 10px;
  align-items: flex-start;
  margin-left: 0%;
  margin-top: 2%;

  > :not(.station-container) {
    margin: 0px 5px;
  }

  .car-group-description {
    font-size: 20px;
    font-weight: 600;
    color: #ff7d0e;
  }

  .car-name {
    color: #525266;
    font-size: 14px;
    font-weight: 500;
  }

  .station-container {
    flex-direction: column;
    margin-top: 5%;

    .left-frame-tablet {
      @media (min-width: ${themeGet('breakpoints.1')}px) {
        display: none;
      }

      @media (max-width: ${themeGet('breakpoints.1')}px) {
        display: flex;
        margin-top: 10px;
        > * {
          margin: 0px 2.5px;
        }
      }
      @media (max-width: ${themeGet('breakpoints.0')}px) {
        display: none;
      }
    }
  }

  @media (max-width: ${themeGet('breakpoints.0')}px) {
    margin: 0px 5px;
    width: 100%;
    align-self: center;
  }
`

export const InfoSecond = styled(FlexEl)`
  flex-direction: column;
  width: 25%;
  align-self: flex-end;

  @media (max-width: ${themeGet('breakpoints.0')}px) {
    width: 100%;
    margin-top: 10px;
  }

  .inner-container {
    flex-direction: row;

    @media (min-width: ${themeGet('breakpoints.1')}px) {
      justify-content: flex-end;
    }

    @media (max-width: ${themeGet('breakpoints.1')}px) {
      justify-content: flex-end;
    }

    @media (max-width: ${themeGet('breakpoints.0')}px) {
      justify-content: flex-end;
    }

    .left-frame-desktop {
      flex-direction: column;
      padding: 10px;
      align-self: flex-end;

      @media (min-width: ${themeGet('breakpoints.1')}px) {
        display: flex;
      }
      @media (max-width: ${themeGet('breakpoints.1')}px) {
        display: none;
      }
      @media (max-width: ${themeGet('breakpoints.0')}px) {
        display: flex;
      }
    }

    .right-frame {
      flex-direction: column;
      padding: 10px;
      align-self: flex-end;

      .total-price {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 10px;
      }
      @media (max-width: ${themeGet('breakpoints.0')}px) {
        .highlight-desktop {
          display: none !important;
        }
      }
    }
  }
`

export const TotalAmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

export const PriceText = styled.div`
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #525266;
`
export const PriceTextHead = styled.div`
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff7d0e;
  text-align: right;
`

export const DefaultOverlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`

export const AmountText = styled.div`
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 520;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
`

export const OldAmountText = styled.div`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 420;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-decoration-line: line-through;
  color: #b4b4c2;
  text-align: right;
`

export const DiscountText = styled.div`
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 520;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #009750;
  text-align: right;
`

export const MonthlyPayText = styled(PriceText)`
  display: inline;
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #0076ff;
`

export const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  position: relative;
  max-width: 60vw;

  &::before {
    content: '';
    top: 17px;
    left: 8.5px;
    width: 2px;
    height: 50%;
    position: absolute;
    background: url(${VerticalDash});
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  }
`

export const OrFromWrapper = styled.div`
  overflow: hidden;
  max-height: ${(props) => (props.affirmMonthlyAmount ? '999px' : '0px')};
  opacity: ${(props) => (props.affirmMonthlyAmount ? '1' : '0')};
  transition: all 0.5s ease-in-out;
  transition-origin: bottom;
`

export const MonthlyPriceWrapper = styled.div`
  opacity: ${(props) => (props.affirmMonthlyAmount ? '1' : '0')};
  max-height: ${(props) => (props.affirmMonthlyAmount ? '999px' : '0px')};
  transition: all 0.5s ease-in-out;
  overflow: hidden;
`

export const UpliftInfoText = styled.span`
  display: hidden;
  cursor: pointer;
  max-height: 0px !important;
`

export const InfoColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const AdsDescription = styled.p`
  font-size: 14px;
  line-heigh: 18px !important;
  margin-bottom: 12px !important;
  margin-top: 0;
  color: #606060;
  font-weight: 500;
  min-height: 38px;
`

export const AdsTitle = styled.h4`
  font-size: 20px;
  line-height: 25px !important;
  margin-bottom: 12px !important;
  color: #ef8435;
  font-weight: 700;
`

export const AdsSub = styled.p`
  font-size: 14px;
  line-heigh: 18px !important;
  margin-top: 12px;
  color: #606060;
  font-weight: 400;
  > b {
    font-weight: 700;
    color: #000000;
  }
`
export const KayakImage = styled.img`
  margin-bottom: 14px !important;
`
