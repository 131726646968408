import React from 'react'
import { useInView } from 'react-intersection-observer'
import { El, FlexEl } from '../layout'
import util from '../../util'
import {
  OuterContainer,
  InnerContainer,
  ImageContainer,
  InfoFirst,
  InfoSecond,
  TotalAmountWrapper,
  PriceTextHead,
  DefaultOverlay,
  AmountText,
  AdsDescription,
  AdsTitle,
  AdsSub,
  KayakImage,
  RefWrapper
} from './KayakCarCard.styles'

function KayakCarCard({ adsProduct }) {
  const {
    deepLink,
    impressionUrl,
    headline,
    description,
    logoUrl,
    site,
    bookingButtonText = 'Book Now',
    priceGroups
  } = adsProduct
  const { ref } = useInView({
    triggerOnce: false,
    threshold: 0.1,
    onChange: (inView) => {
      if (inView) {
        navigator.sendBeacon(impressionUrl)
      }
    }
  })

  return (
    <RefWrapper ref={ref}>
      <OuterContainer>
        <InnerContainer>
          <ImageContainer className='img-container'>
            <img className='car-img' src={util.toHttps(logoUrl)} alt={site} />
          </ImageContainer>
          <InfoFirst>
            <AdsTitle>{headline}</AdsTitle>
            <AdsDescription>{description}</AdsDescription>
            <AdsSub>
              {site ? `${site} / ` : ''} <b>Sponsored</b>
            </AdsSub>
          </InfoFirst>
          <InfoSecond>
            <FlexEl className='inner-container'>
              <FlexEl className='right-frame'>
                <KayakImage
                  width={150}
                  height={58}
                  src={'https://caroline-supplier-images.s3.eu-west-1.amazonaws.com/KAYAK_Logo.png'}
                  alt='kayak ads'
                />
                <El className='total-price'>
                  <TotalAmountWrapper>
                    {priceGroups && (
                      <DefaultOverlay>
                        <PriceTextHead>Total Price</PriceTextHead>
                        <AmountText>{util.formatMoneyFloored(priceGroups.price, priceGroups.currency)}</AmountText>
                      </DefaultOverlay>
                    )}
                  </TotalAmountWrapper>
                </El>
              </FlexEl>
            </FlexEl>
          </InfoSecond>
        </InnerContainer>
        <El className='book-button'>
          <a target='_blank' rel='noreferrer' href={deepLink}>
            {bookingButtonText}
          </a>
        </El>
      </OuterContainer>
    </RefWrapper>
  )
}

KayakCarCard.propTypes = {}

export default KayakCarCard
