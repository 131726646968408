import util from './index'
import isEmpty from 'lodash/isEmpty'

const price = {
  payNowTotalAmount(extendedPrice) {
    return this.actualDeposit(extendedPrice) + this.extra(extendedPrice)
  },

  waitlistPrice(extendedPrice) {
    return this.payNowTotalAmount(extendedPrice) // extras should be zero when HDSERV
  },

  payWhenBookedPrice(extendedPrice) {
    return extendedPrice.rentalPrice.whenBookedFee
  },

  actualDeposit(extendedPrice) {
    return extendedPrice.rentalPrice.discountedDeposit || extendedPrice.rentalPrice.fullDeposit
  },

  payNowTotalAmountBeforeDiscount(extendedPrice) {
    const deposit = extendedPrice.rentalPrice.fullDeposit
    return deposit + this.extra(extendedPrice)
  },

  extra(extendedPrice) {
    if (isEmpty(extendedPrice?.ancillaryProducts)) return 0
    return extendedPrice?.ancillaryProducts?.reduce(
      (acc, cur) => (extendedPrice.selectedAncillaries.includes(cur.id) ? acc + cur.price.price : acc),
      0
    )
  },

  discountAmount(extendedPrice) {
    return (
      extendedPrice.rentalPrice.fullDeposit -
      (extendedPrice.rentalPrice.discountedDeposit || extendedPrice.rentalPrice.fullDeposit)
    )
  },

  currency(extendedPrice) {
    return extendedPrice.rentalPrice.currency
  },

  isDiscounted(extendedPrice) {
    return extendedPrice.rentalPrice.discountedDeposit !== null
  },

  isFullyPrepaid(extendedPrice) {
    return extendedPrice.rentalPrice.remainder < 1e-5
  },

  priceState(extendedPrice) {
    return this.isFullyPrepaid(extendedPrice)
      ? this.isDiscounted(extendedPrice)
        ? 'FULL_PREPAYMENT_WITH_DISCOUNT'
        : 'FULL_PREPAYMENT'
      : this.isDiscounted(extendedPrice)
      ? 'DISCOUNTED'
      : 'NORMAL'
  },

  payNowPrice(extendedPrice) {
    this.isDiscounted(extendedPrice)
      ? this.payNowTotalAmountBeforeDiscount(extendedPrice)
      : this.payNowTotalAmount(extendedPrice)
  },

  payAtCounterPrice(extendedPrice) {
    return extendedPrice.rentalPrice.remainder
  },

  grandTotalNormalCars(extendedPrice) {
    return this.payNowTotalAmount(extendedPrice) + this.payAtCounterPrice(extendedPrice)
  },

  grandTotalHDSERVCars(extendedPrice) {
    return (
      this.payNowTotalAmount(extendedPrice) +
      this.payWhenBookedPrice(extendedPrice) +
      this.payAtCounterPrice(extendedPrice)
    )
  },

  getPriceInfoDetailsForPayNow(extendedPrice) {
    const priceInfoDetails = []
    let priceInfoItem = {
      header: 'Prepayment',
      description:
        util.formatMoney(this.actualDeposit(extendedPrice), this.currency(extendedPrice)) +
        ' will be charged from your card now to guarantee your reservation.'
    }
    priceInfoDetails.push(priceInfoItem)

    const ancillaryItems = this.makePriceInfoItemsForAncillaries(extendedPrice)
    priceInfoDetails.push(...ancillaryItems)

    extendedPrice.extraRentalPrice && priceInfoDetails.push(...extendedPrice.extraRentalPrice)
    return {
      title: this.isFullyPrepaid(extendedPrice)
        ? 'Pay for your rental now and save money!'
        : "You don't have to pay the total price now!",
      subtitle: `${util.formatMoney(
        this.payNowTotalAmount(extendedPrice),
        this.currency(extendedPrice)
      )} will be charged from your card.`,
      items: priceInfoDetails
    }
  },

  makePriceInfoItemsForAncillaries(extendedPrice) {
    return extendedPrice.ancillaryProducts
      .filter((ap) => extendedPrice.selectedAncillaries.includes(ap.id))
      .map((ap) => {
        const priceString = util.formatMoney(ap.price.price, ap.price.currency)
        return {
          header: ap.title,
          description: `${priceString} will be charged from your card for ${ap.title}.`
        }
      })
  },

  getPriceInfoDetailsForWaitlistFee(extendedPrice) {
    const priceString = util.formatMoney(this.waitlistPrice(extendedPrice), extendedPrice.rentalPrice.currency)

    const waitlistFeeDetails = [
      {
        header: 'Waitlist Fee',
        description: `We will contact multiple car rental companies to secure this car for you.`
      },
      {
        header: '',
        description: `We will deduct this amount from your rental amount and charge you the prepayment once the rental is secured.`
      },
      {
        header: '',
        description: `In the unlikely event of all the cars are sold out, the waitlist fee of ${priceString} is not refundable.`
      }
    ]

    return {
      title: "You don't have to pay the total price now!",
      subtitle: `${util.formatMoney(
        this.waitlistPrice(extendedPrice),
        this.currency(extendedPrice)
      )} will be charged from your card.`,
      items: waitlistFeeDetails
    }
  },

  getPriceInfoDetailsForPayWhenBooked(extendedPrice) {
    const priceString = util.formatMoney(this.payWhenBookedPrice(extendedPrice), extendedPrice.rentalPrice.currency)

    const payWhenBookedDetails = [
      {
        header: 'Prepayment',
        description: `${priceString} will be charged from your card to guarantee your reservation when this car is secured.`
      },
      {
        header: '',
        description: `This amount is the prepayment minus the waitlist fee.`
      }
    ]

    return {
      title: '',
      subtitle: '',
      items: payWhenBookedDetails
    }
  }
}
export default price
