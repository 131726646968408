import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import debug from 'debug'
import API from '../../services/API'
import util from '../../util'
import { PageContainer } from '../../components/layout'
import SessionManager from '../../services/SessionManager'
import Notification from '../../services/Notification'
import {
  setSearchDropOffLocation,
  setSearchId,
  setPromoCode,
  setSearchPickupLocation,
  setSearchArgs,
  setCarRentalProduct
} from '../../redux/slices'
import { CarlinPropTypes } from '../../entities'
import { selectSearchArgs } from '../../redux/selectors'
import EventSender from '../../services/EventSender'
import { NoCarModal } from '../../components/CarListing/NoCarModal'
import { CarSearchResults } from '../../components/CarListing/CarSearchResults'
import isEqual from 'lodash/isEqual'

const log = debug('carla:search')

class CarSearchResultsContainer extends Component {
  static propTypes = {
    carRentalProduct: CarlinPropTypes.CarRentalProduct
  }

  state = {
    loading: false,
    data: null,
    adsData: null,
    error: null,
    isNoCars: true,
    searchExpanded: false,
    productHash: null, // id of car to scroll set on deepLink
    bookCar: false, // book the car automatically if true,
    isSearchNew: false
  }

  terms = {
    partOne: '',
    partTwo: ''
  }

  searchArgs = null
  noCarsResponse = null // Response from the backend which is sent to NoCarModal component if no cars appears

  constructor(props) {
    super(props)
    Notification.askPermission()
    log('props in constructor', props)
    const urlParams = util.getUrlParams(this.props)

    this.searchArgs = {
      pickupDateStr: urlParams.pickupDateStr,
      dropOffDateStr: urlParams.dropOffDateStr,
      pickupTime: util.getTimeObjFromAPI(urlParams.pickupDateStr),
      dropOffTime: util.getTimeObjFromAPI(urlParams.dropOffDateStr),
      pickupDateTime: util.getDateFromAPI(urlParams.pickupDateStr),
      dropOffDateTime: util.getDateFromAPI(urlParams.dropOffDateStr),
      age: props.age,
      searchId: props.searchId
    }

    if (props.pickupLocation) {
      this.searchArgs.pickupLocation = props.pickupLocation.value
      this.searchArgs.pickupLocationName = props.pickupLocation.label
    }
    if (props.dropOffLocation) {
      this.searchArgs.dropOffLocation = props.dropOffLocation.value
      this.searchArgs.dropOffLocationName = props.dropOffLocation.label
    }

    // check if url contains a car result to scroll
    if (urlParams.productHash) {
      this.state.productHash = urlParams.productHash
      if (urlParams.book) {
        this.state.bookCar = true
      }
    }
  }

  // update search parameters (location labels) from car search results
  updateCarSearchInfoFromData = (data) => {
    // update location info from first car search result (if user has come from url/deepLink)
    if (!data.cars || data.cars.length === 0) return

    const pickupLocation = data.cars[0].station
    // update location names if locations ids are set from deep links
    if (pickupLocation && this.props.pickupLocation && this.props.pickupLocation.label.length === 0) {
      this.props.setSearchPickupLocation({
        label: pickupLocation.locationName,
        value: pickupLocation.id
      })
    }

    const dropOffLocation = data.cars[0].dropOffStation
    if (dropOffLocation && this.props.dropOffLocation && this.props.dropOffLocation.label.length === 0) {
      this.props.setSearchDropOffLocation({
        label: dropOffLocation.locationName,
        value: dropOffLocation.id
      })
    }

    // update search id to be attached to reservation
    this.props.setSearchId(data.searchId)
  }

  // check if there is a car to select if deepLink
  checkXO1Redirect = (data) => {
    const { productHash, bookCar } = this.state
    if (!productHash || !bookCar) return

    const theCar = data.cars.find((carRentalProduct) => carRentalProduct.productHash === productHash)
    if (!theCar) return

    this.handleBook(theCar)
  }

  handleNoCarsOptions = (noCarsResponse, data) => {
    this.noCarsResponse = noCarsResponse // send to noCarModel as props

    const newState = {
      loading: false,
      error: false,
      isNoCars: true
    }

    const urlParams = util.getUrlParams(this.props)
    const { pickupLocationId, dropOffLocationId, pickupDateStr, dropOffDateStr, age } = urlParams

    SessionManager.saveLastCarRentalProducts(
      { pickupLocationId, dropOffLocationId, pickupDateStr, dropOffDateStr, age },
      data
    )

    this.setState(newState)
  }

  // handle search result data
  handleData = (data) => {
    const searchParams = util.getUrlParams(this.props)
    const { pickupLocationId, dropOffLocationId, pickupDateStr, dropOffDateStr, age } = searchParams

    //Handle if no cars
    if (typeof data.cars == 'undefined' || data.cars == null || data.cars.length == null || data.cars.length <= 0) {
      // no cars
      try {
        API.getNoCarsOptions({
          carSearchArgs: {
            pickupDateTime: pickupDateStr,
            dropOffDateTime: dropOffDateStr,
            pickupLocation: pickupLocationId,
            dropOffLocation: dropOffLocationId,
            age: age || 26,
            promoCode: null,
            debitFriendly: false,
            parentSearchId: null
          }
        }).then((noCarsData) => this.handleNoCarsOptions(noCarsData, data))
      } catch (err) {
        console.error('error while calling getNoCarsOptions API', err)
        this.handleNoCarsOptions(null)
      }

      log('no car found')
      return
    }

    const newState = {
      loading: false,
      error: false,
      data: data,
      isNoCars: false
    }

    // open modal if no car found
    if (data) {
      if (data.cars) {
        data.cars = data.cars.map((carResult, i) => ({
          ...carResult,
          rank: i
        }))
      }
      // used later on booking
      this.terms = {
        partOne: data.termsPartOne,
        partTwo: data.termsPartTwo
      }
      this.updateCarSearchInfoFromData(data)

      SessionManager.saveLastCarRentalProducts(
        { pickupLocationId, dropOffLocationId, pickupDateStr, dropOffDateStr, age },
        data
      )
      EventSender.catalogSeen(data)
      this.checkXO1Redirect(data)
    }

    this.setState(newState)
  }

  handleBook = async (carRentalProduct) => {
    API.getSelectedCar(carRentalProduct).then((data) => {
      if (data) localStorage.setItem('productID', JSON.stringify(data))
      else log('failed to fetch selected')
    })
    // embed pickup/drop-off dates into selected car search result
    carRentalProduct.searchArgs = this.searchArgs
    carRentalProduct.searchId = this.props.searchId
    EventSender.carSelected(carRentalProduct)
    this.props.setCarRentalProduct(carRentalProduct)
    const urlParams = util.getUrlParams(this.props)
    const { pickupLocationId, dropOffLocationId, pickupDateStr, dropOffDateStr, age } = urlParams
    const pickupRequest = await API.getPlace(pickupLocationId)
    const pickupLocationName = pickupRequest.prettyName
    let dropOffLocationName = pickupLocationName
    if (pickupLocationId !== dropOffLocationId) {
      const dropOffRequest = await API.getPlace(dropOffLocationId)
      dropOffLocationName = dropOffRequest.prettyName
    }

    this.props.setSearchArgs({
      pickupLocation: {
        value: pickupLocationId,
        label: pickupLocationName
      },
      dropOffLocation: {
        value: dropOffLocationId,
        label: dropOffLocationName
      },
      pickupDateStr: util.getDateFromAPI(pickupDateStr).toString(),
      dropOffDateStr: util.getDateFromAPI(dropOffDateStr).toString(),
      age: age || 26,
      pickupTime: util.getTimeObjFromAPI(pickupDateStr),
      dropOffTime: util.getTimeObjFromAPI(dropOffDateStr),
      pickupDate: util.getDateFromAPI(pickupDateStr),
      dropOffDate: util.getDateFromAPI(dropOffDateStr),
      pickupDateTime: util.getDateFromAPI(pickupDateStr),
      dropOffDateTime: util.getDateFromAPI(dropOffDateStr)
    })
    log('props before xo1', this.props)
    this.props.history.push('/reservation')
  }

  handleAdsData = (adsData) => {
    this.setState({
      adsData
    })
  }

  onToggleSearch = () => this.setState({ searchExpanded: !this.state.searchExpanded })

  changeSearch = () => {
    try {
      const { pickupLocation, dropOffLocation, pickupDateTime, dropOffDateTime, age } = this.props
      const pickupTimeStr = util.formatDateForAPI(pickupDateTime)
      const dropOffTimeStr = util.formatDateForAPI(dropOffDateTime)

      const args = {
        pickupLocation: pickupLocation.value,
        dropOffLocation: dropOffLocation.value,
        pickupDateTime: pickupTimeStr,
        dropOffDateTime: dropOffTimeStr,
        age: age
      }
      this.searchForNoCars(args)
    } catch (err) {
      console.error('error while submitting search form', err)
    }
  }

  searchForNoCars = (args) => {
    try {
      this.setState({
        loading: true
      })
      const { pickupLocation, dropOffLocation, pickupDateTime, dropOffDateTime, age } = args
      let url = `/rental-cars/${pickupLocation}/${dropOffLocation}/${pickupDateTime}/${dropOffDateTime}`
      if (age && Number(age) < 25) url += `?age=${age}`
      log('redirecting to search page:', url)
      this.props.history.replace(url)
    } catch (err) {
      console.error('error while submitting search form', err)
    }
  }

  search = async () => {
    log('searching cars', this.props)
    const promoCode = this.props.promoCode
    const searchParams = util.getUrlParams(this.props)
    log('SearchParams: ', searchParams)
    const { pickupLocation: oldPickupLocation, dropOffLocation: oldDropOffLocation } = this.props
    const { pickupLocationId, dropOffLocationId, pickupDateStr, dropOffDateStr, age } = searchParams

    this.setState({
      loading: true,
      error: null,
      data: null
    })

    if (!oldPickupLocation || oldPickupLocation.value !== pickupLocationId) {
      const pickupRequest = await API.getPlace(pickupLocationId)
      this.searchArgs.pickupLocationName = pickupRequest.prettyName
    } else {
      this.searchArgs.pickupLocationName = oldPickupLocation.label || ''
    }

    if (pickupLocationId === dropOffLocationId) {
      this.searchArgs.dropOffLocationName = this.searchArgs.pickupLocationName
    } else {
      if (!oldDropOffLocation || oldDropOffLocation.value !== dropOffLocationId) {
        const dropOffRequest = await API.getPlace(dropOffLocationId)
        this.searchArgs.dropOffLocationName = dropOffRequest.prettyName
      } else {
        this.searchArgs.dropOffLocationName = oldDropOffLocation.label || ''
      }
    }

    this.searchArgs.pickupDateTime = util.getDateFromAPI(pickupDateStr)
    this.searchArgs.dropOffDateTime = util.getDateFromAPI(dropOffDateStr)
    this.searchArgs.pickupTime = util.getTimeObjFromAPI(pickupDateStr)
    this.searchArgs.dropOffTime = util.getTimeObjFromAPI(dropOffDateStr)
    this.searchArgs.pickupDateStr = pickupDateStr
    this.searchArgs.dropOffDateStr = dropOffDateStr

    this.props.setSearchArgs({
      pickupLocation: {
        value: pickupLocationId,
        label: this.searchArgs.pickupLocationName,
        type: this.searchArgs.pickupLocationType
      },
      dropOffLocation: {
        value: dropOffLocationId,
        label: this.searchArgs.dropOffLocationName,
        type: this.searchArgs.dropOffLocationType
      },
      pickupDateStr: util.getDateFromAPI(pickupDateStr).toString(),
      dropOffDateStr: util.getDateFromAPI(dropOffDateStr).toString(),
      age: age || 26,
      pickupTime: util.getTimeObjFromAPI(pickupDateStr),
      dropOffTime: util.getTimeObjFromAPI(dropOffDateStr),
      pickupDate: util.getDateFromAPI(pickupDateStr),
      dropOffDate: util.getDateFromAPI(dropOffDateStr),
      pickupDateTime: util.getDateFromAPI(pickupDateStr),
      dropOffDateTime: util.getDateFromAPI(dropOffDateStr)
    })

    //Search normal
    const requestBody = {
      pickupLocation: pickupLocationId,
      dropOffLocation: dropOffLocationId,
      pickupDateTime: pickupDateStr,
      dropOffDateTime: dropOffDateStr,
      age: age || 26,
      promoCode: promoCode
    }
    this.setState({
      isSearchNew: true
    })
    Promise.all([
      API.searchCars(requestBody).catch((error) => error)
    ]).then((values) => {
      this.handleData(values[0])
      if (values[1]) {
        this.handleAdsData(values[1].adItems)
      }
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const oldParams = util.getUrlParams(prevProps)
    const newParams = util.getUrlParams(this.props)

    if (!isEqual(newParams, oldParams)) {
      this.search()
    }
  }

  componentDidMount() {
    this.search()
  }

  handleIsNewSearch = () => {
    this.setState({
      isSearchNew: false
    })
  }

  render() {
    return (
      <PageContainer bg='newColorPrimary'>
        {!this.state.isNoCars && (
          <CarSearchResults
            {...this.props}
            {...this.state}
            searchArgs={this.searchArgs}
            onBook={this.handleBook}
            onSearchClicked={this.changeSearch}
            onToggleSearch={this.onToggleSearch}
            searchExpanded={this.state.searchExpanded}
            isNewSearch={this.state.isSearchNew}
            handleIsNewSearch={this.handleIsNewSearch}
          />
        )}
        {this.state.isNoCars && (
          <NoCarModal
            {...this.props}
            {...this.state}
            noCarsResponse={this.noCarsResponse}
            searchExpanded={this.state.searchExpanded}
            onToggleSearch={this.onToggleSearch}
            onSearchClicked={this.changeSearch}
            searchForNoCars={this.searchForNoCars}
          />
        )}
      </PageContainer>
    )
  }
}

export default withRouter(
  connect(selectSearchArgs, {
    setSearchArgs,
    setSearchPickupLocation,
    setSearchDropOffLocation,
    setCarRentalProduct,
    setSearchId,
    setPromoCode
  })(CarSearchResultsContainer)
)
