import React, { Component, useContext, useState } from 'react'
import { Provider } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import util from './util'
import loadable from '@loadable/component'
import NavigationBar from './layout/NavigationBar'
import { GlobalStyle, theme } from './styles'
import SessionManager from './services/SessionManager'
import { PageMainContainer } from './components/layout'
import EventSender from './services/EventSender'
import clone from 'lodash/clone'
import Router from './Router'
import { QueryClient, QueryClientProvider } from 'react-query'
import { store as reduxStore } from './redux/store'
import { Auth0Provider } from '@auth0/auth0-react'
import { DefaultErrorModal } from './components/Modal'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60
    }
  }
})

export const CarlaContext = React.createContext()

const ReactToastify = loadable(() => import('./components/common/ReactToastify'), {
  ssr: false
})

const NavBarContext = React.createContext()
const UpdateNavBarContext = React.createContext()

export const useBarKey = () => {
  return useContext(NavBarContext)
}

export const useSetBarKey = () => {
  return useContext(UpdateNavBarContext)
}

const DynamicNavBarContext = ({ children }) => {
  const [navBarKey, setNavBarKey] = useState('REGULAR')

  function setKey(arg) {
    let key = arg !== null ? arg : 'REGULAR'
    setNavBarKey(key)
  }

  return (
    <NavBarContext.Provider value={navBarKey}>
      <UpdateNavBarContext.Provider value={setKey}>{children}</UpdateNavBarContext.Provider>
    </NavBarContext.Provider>
  )
}

class App extends Component {
  state = {
    error: null,
    context: {
      history: null,
      location: null
    }
  }

  constructor(props) {
    super(props)
    // debug module browser setting
    if (util.isDevelopment() && util.isBrowser() && util.isCookiesEnabled()) {
      localStorage.debug = 'carla:*'
      // tool to do various operations
      window.dev = util.dev
    }

    // used by optimize
    if (util.isBrowser()) {
      window.store = reduxStore
    }

    // set global context provider values
    this.state.context = {
      history: props.history,
      location: props.location
    }
  }

  // redirect to home page when user closes error modal
  handleAfterError = () => {
    this.setState({ error: null })
    this.props.history.push('/')
  }

  componentDidMount() {
    // set device id and user country
    SessionManager.startSession()
    // send session end event when closing tab
    window.addEventListener('beforeunload', () => {
      EventSender.sessionEnded()
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const isLocationChanged = prevProps.location !== this.props.location

    const newContext = clone(this.state.context)

    if (isLocationChanged) {
      newContext.location = this.props.location
      this.setState({ context: newContext })
    }
  }

  /**
   * show "something is wrong" and send error to sentry
   */
  componentDidCatch(error, errorInfo) {
    this.setState({ error })
  }

  render() {
    return (
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin
        }}
      >
        <Provider store={reduxStore}>
          <QueryClientProvider client={queryClient}>
            <CarlaContext.Provider value={this.state.context}>
              <ThemeProvider theme={theme}>
                <GlobalStyle />
                <DynamicNavBarContext>
                  <NavigationBar currentPage={this.state.context.location} />
                  <PageMainContainer>
                    {Boolean(this.state.error) && (
                      <DefaultErrorModal isOpen={true} onClose={this.handleAfterError} closable={true} ok={true} />
                    )}
                    {!this.state.error && <Router />}
                  </PageMainContainer>
                </DynamicNavBarContext>
                <ReactToastify />
              </ThemeProvider>
            </CarlaContext.Provider>
          </QueryClientProvider>
        </Provider>
      </Auth0Provider>
    )
  }
}

export default withRouter(App)
