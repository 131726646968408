import React from 'react'
import { CheckBoxWrapper, CheckBoxUI, CheckBoxImg, SameLocationText } from './CheckBox.styled'
import OrangeCheck from '../../util/images/icon-check-stroke.svg'

function CheckBox({ label, isChecked, onChange, ...rest }) {
  return (
    <CheckBoxWrapper {...rest}>
      <CheckBoxUI onClick={() => onChange(!isChecked)}>
        {isChecked && <CheckBoxImg src={OrangeCheck} alt='orange-check-icon' />}
      </CheckBoxUI>
      <SameLocationText>{label}</SameLocationText>
    </CheckBoxWrapper>
  )
}

export default CheckBox
