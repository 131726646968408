import { configureStore } from '@reduxjs/toolkit'
import debounce from 'lodash/debounce'
import pick from 'lodash/pick'
import hotelBookingSlice from './slices/hotelBookingSlice'
import promoCodeIsOpenSlice from './slices/promoCodeIsOpenSlice'
import searchArgsSlice from './slices/searchArgsSlice'
import footerSlice from './slices/footerSlice'
import nearMeDataSlice from './slices/nearMeDataSlice'
import userSlice from './slices/userSlice'
import nearMePickedSlice from './slices/nearMePickedSlice'
import locationSearchSlice from './slices/locationSearchSlice'
import userDetailsSlice from './slices/userDetailsSlice'
import extrasSelectedSlice from './slices/extrasSelectedSlice'
import carRentalProductSlice from './slices/carRentalProductSlice'
import xo1DetailsSlice from './slices/xo1DetailsSlice'
import reservationResponseSlice from './slices/reservationResponseSlice'
import reservationInfoRetrieval from './slices/reservationInfoRetrievalSlice'
import optimizeSlice from './slices/optimizeSlice'
import carSearchFiltersSlice from './slices/carSearchFiltersSlice'
import filterTagsSlice from './slices/filterTagsSlice'
import sortingOptionSlice from './slices/sortingOptionSlice'
import threeDsSlice from './slices/threeDsSlice'
import bundleBookingSlice from './slices/bundleBookingSlice'
import util from '../util'

const STORAGE_KEY = 'portal_store_v3'

const storedState = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem(STORAGE_KEY)) : null

export const store = configureStore({
  reducer: {
    hotelBooking: hotelBookingSlice,
    promoCodeIsOpen: promoCodeIsOpenSlice,
    searchArgs: searchArgsSlice,
    footer: footerSlice,
    nearMeData: nearMeDataSlice,
    user: userSlice,
    nearMePicked: nearMePickedSlice,
    locationSearch: locationSearchSlice,
    userDetails: userDetailsSlice,
    extrasSelected: extrasSelectedSlice,
    carRentalProduct: carRentalProductSlice,
    xo1Details: xo1DetailsSlice,
    reservationResponse: reservationResponseSlice,
    reservationInfoRetrieval,
    optimize: optimizeSlice,
    carSearchFilters: carSearchFiltersSlice,
    filterTags: filterTagsSlice,
    sortingOption: sortingOptionSlice,
    threeDs: threeDsSlice,
    bundleBooking: bundleBookingSlice
  },
  preloadedState: storedState === null ? undefined : storedState
})

// Create a debounced version of the storage update function
const debouncedStorageUpdate = debounce(() => {
  const state = store.getState()
  util.saveToLocalStorage(
    STORAGE_KEY,
    pick(state, [
      'user',
      'searchArgs',
      'userDetails',
      'carRentalProduct',
      'xo1Details',
      'reservationResponse',
      'hotelBooking',
      'bundleBooking',
      'locationSearch'
    ])
  )
  // 86400000 = 1 day
  util.saveToLocalStorageWithExpiry('countryCodeExpiry', new Date().getTime(), 86400000)
}, 1000) // Adjust the debounce delay as needed

// Subscribe to store updates and call the debounced function
store.subscribe(debouncedStorageUpdate)
