import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Reservations from './routes/Reservations'
import Contact from './routes/Contact'
import PageNotFound from './routes/PageNotFound'
import HomePage from './routes/Home'
import CarSearchResults from './routes/CarListing'
import Login from './routes/Login'
import PrivateRoute from './routes/PrivateRoute'
import loadable from '@loadable/component'

const XO1 = loadable(() => import('./routes/XO1'), {
  ssr: false
})
const XO2 = loadable(() => import('./routes/XO2'), {
  ssr: false
})
const MockXo2 = loadable(() => import('./routes/XO2/mockXO2'), {
  ssr: false,
  resolveComponent: (components) => components.default
})
const Success = loadable(() => import('./routes/Success'), {
  ssr: false
})

function Router() {
  return (
    <Switch>
      <PrivateRoute exact key={'home'} path={'/'}>
        <HomePage />
      </PrivateRoute>
      <PrivateRoute exact key={'reservations'} path={'/reservations'}>
        <Reservations />
      </PrivateRoute>
      <PrivateRoute
        exact
        key={'rental-cars'}
        path={'/rental-cars/:pickupLocationId/:dropOffLocationId/:pickupDateStr/:dropOffDateStr'}
      >
        <CarSearchResults />
      </PrivateRoute>
      <PrivateRoute exact key={'xo1'} path={'/reservation'} component={XO1} />
      <PrivateRoute key={'xo2'} path={'/payment'} component={XO2} />
      <PrivateRoute exact key={'mockXO2'} path={'/mock-payment'} component={MockXo2} />
      <PrivateRoute exact key={'success'} path={'/success'} component={Success} />
      <Route exact key={'/login'} path={'/login'} component={Login} />
      <Route exact key={'contact-us'} path={'/contact-us'} component={Contact} />
      {/* Landing pages */}

      <Route exact key={'fallback'} path={'*'} component={PageNotFound} />
    </Switch>
  )
}

export default Router
