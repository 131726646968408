import React from 'react'

import MapBackground from '../../util/images/map-background.png'
import MapIcon from '../../util/images/map-icon.png'

//Styling
import './SeeOnMap.css'

const SeeOnMap = ({ history }) => {
  const handleClick = () => {
    history.goBack()
  }

  return (
    <div
      className='see-on-map'
      onClick={handleClick}
      style={{ backgroundImage: `url(${MapBackground})`, cursor: 'pointer' }}
    >
      <span className='see-on-map-icon-container'>
        <img className='see-on-map-icon' alt='map-icon' src={MapIcon} />
      </span>
      <span className='see-on-map-text'>See on map</span>
    </div>
  )
}

export default SeeOnMap
