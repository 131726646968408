import React, { useState, useEffect } from 'react'
import IconArrowDown from '../../util/images/icon-arrow-down.png'
import IconCheckbox from '../../util/images/icon-checkbox.png'
import IconCancelSmall from '../../util/images/icon-cancel-small.png'
import MobileFilterIcon from '../../util/images/icon-mobile-filter.png'
//Styling
import './Filters.css'
import GA from '../../services/GA'
import { cloneDeep } from 'lodash'

export const FilterMenu = ({
  filters,
  selectedFilters,
  selectFilter,
  clearAllFilters,
  setTabletMenuOpen,
  filterCounts
}) => {
  //Set the initial state of open categories to true
  const initialOpenCategories = {}
  Object.keys(filters).map((filter) => (initialOpenCategories[filter] = true))
  const [openCategories, setOpenCategories] = useState(initialOpenCategories)

  const clearAllFilter = () => {
    clearAllFilters()
    setTabletMenuOpen(false)
  }
  const categoryMapping = {
    carType: 'Car Type',
    fuel: 'Fuel',
    transmission: 'Transmission',
    seats: 'Seats',
    cancellationPolicy: 'Cancellation Policy',
    supplier: 'Supplier',
    broker: 'Broker',
    fullyPrepaid: 'Fully Prepaid'
  }

  const onArrowClick = (filterCategory) => {
    let temp = { ...openCategories }
    temp[filterCategory] = !temp[filterCategory]
    setOpenCategories(temp)
  }

  const renderCategories = () => {
    if (filters.supplier) {
      filters.supplier.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }))
    }
    const filterCategories = Object.keys(filters)
    let firstOnePlaced = false
    return filterCategories.map((filterCategory) => {
      if (filters[filterCategory].length > 1) {
        return (
          <div className='filter-category-container' key={filterCategory}>
            {firstOnePlaced ? <hr className='category-divider' /> : (firstOnePlaced = true)}
            <div className='filter-category-header'>
              <span>{categoryMapping[filterCategory]}</span>
              <img
                onClick={() => onArrowClick(filterCategory)}
                className='filter-header-arrow-icon'
                alt='arrow-icon'
                src={IconArrowDown}
                style={openCategories[filterCategory] ? {} : { transform: 'rotate(180deg)' }}
              />
            </div>
            <div
              className={
                openCategories[filterCategory]
                  ? 'filter-subcategories-container open'
                  : 'filter-subcategories-container closed'
              }
            >
              {filters[filterCategory].map((subcategory) => {
                return (
                  <div
                    className='filter-subcategory-container'
                    onClick={() => selectFilter(filterCategory, subcategory)}
                    key={subcategory}
                  >
                    <span className='checkbox-container'>
                      <img
                        className='checkbox'
                        alt='checkbox'
                        src={IconCheckbox}
                        style={selectedFilters[filterCategory]?.includes(subcategory) ? {} : { opacity: '0' }}
                      />
                    </span>
                    <span className='subcategory-text'>{subcategory}</span>
                    <span className='filter-count-tag'>
                      {filterCounts[filterCategory]
                        ? filterCounts[filterCategory][subcategory]
                          ? filterCounts[filterCategory][subcategory]
                          : 0
                        : null}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        )
      }
      return null
    })
  }

  return (
    <div className='filter-menu-container'>
      <div className='filter-header'>
        <span>Filters</span>
        <button className='desktop-clear-all-button' onClick={clearAllFilter}>
          Clear All
        </button>
        <img
          className='tablet-icon-cancel-small'
          onClick={() => setTabletMenuOpen(false)}
          src={IconCancelSmall}
          alt='cancel-icon'
        />
      </div>
      {renderCategories()}
      <button className='desktop-clear-all-button-bottom' onClick={clearAllFilter}>
        Clear All Filters
      </button>
    </div>
  )
}

export const MobileFilterMenu = ({
  filters,
  selectedFilters,
  setSelectedFilters,
  clearAllFilters,
  filterTags,
  setFilterTags,
  setHeaderOpen,
  filterCounts,
  scrollToTop
  /*
    ApplyButtonText = 'Apply',
    ClearButtonText = 'Clear',
    updateShowCarsCount
    */
}) => {
  //Controlling whether the menu is open or not
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)
  //In the tablet we are not applying as we select the filters. we are applying them when we predd the apply button. this state is used for that.
  const [tempSelectedFilters, setTempSelectedFilters] = useState(selectedFilters)
  const [tempFilterTags, setTempFilterTags] = useState(filterTags)
  const [filterArr, setFilterArr] = useState([])

  //Set the initial state of open categories to true
  const initialOpenCategories = {}
  Object.keys(filters).map((filter) => (initialOpenCategories[filter] = true))
  const [openCategories, setOpenCategories] = useState(initialOpenCategories)

  /*
    useEffect(() => {
        if (updateShowCarsCount) {
            updateShowCarsCount(tempSelectedFilters)
        }
    }, [tempSelectedFilters])
    */

  useEffect(() => {
    //Every time filter menu is opnede or closed, reset tempSelectedFilters and tempFilterTags
    setTempSelectedFilters(selectedFilters)
    setTempFilterTags(filterTags)

    //When the filter menu opens on tablet and mobile, lock the back screen for scrolling
    if (filterMenuOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'initial'
    }
  }, [filterMenuOpen, selectedFilters])

  const categoryMapping = {
    carType: 'Car Type',
    fuel: 'Fuel',
    transmission: 'Transmission',
    seats: 'Seats',
    cancellationPolicy: 'Cancellation Policy',
    supplier: 'Supplier'
  }

  const onArrowClick = (filterCategory) => {
    let temp = { ...openCategories }
    temp[filterCategory] = !temp[filterCategory]
    setOpenCategories(temp)
  }

  const onSelectFilter = (category, subcategory) => {
    let newFilters = cloneDeep(tempSelectedFilters)
    let newFilterTags = tempFilterTags.map((e) => e)

    if (newFilters[category].includes(subcategory)) {
      const index = newFilters[category].indexOf(subcategory)
      newFilters[category].splice(index, 1)

      const tagIndex = newFilterTags.findIndex((filterTag) => filterTag[0] === category && filterTag[1] === subcategory)
      newFilterTags.splice(tagIndex, 1)
    } else {
      newFilters[category].push(subcategory)
      newFilterTags.push([category, subcategory])
      setFilterArr(newFilterTags)
    }
    setTempSelectedFilters(newFilters)
    setTempFilterTags(newFilterTags)
  }

  const applyFilters = () => {
    setSelectedFilters(tempSelectedFilters)
    if (setFilterTags) setFilterTags(tempFilterTags)
    setFilterMenuOpen(false)
    setHeaderOpen && setHeaderOpen(true)
    scrollToTop()
  }

  const clearFilters = () => {
    if (filterArr.length > 0) {
      clearAllFilters()
      setFilterArr([])
    }
    setFilterMenuOpen(false)
    setHeaderOpen(true)
  }

  const closeFilterAndHeader = () => {
    setFilterMenuOpen(false)
    setHeaderOpen && setHeaderOpen(false)
  }
  const closeHeaderAndToggleFilter = () => {
    setFilterMenuOpen(!filterMenuOpen)
    setHeaderOpen(true)
    GA.tappedFilter()
  }
  const setFilterMenuOpenAndSendGAEvent = () => {
    setFilterMenuOpen(!filterMenuOpen)
    GA.tappedFilter()
  }

  const renderCategories = () => {
    const filterCategories = Object.keys(filters)
    let firstOnePlaced = false
    return filterCategories.map((filterCategory) => {
      if (filters[filterCategory].length > 1) {
        return (
          <div className='filter-category-container' key={filterCategory}>
            {firstOnePlaced ? <hr className='category-divider' /> : (firstOnePlaced = true)}
            <div className='filter-category-header'>
              <span>{categoryMapping[filterCategory]}</span>
              <img
                onClick={() => onArrowClick(filterCategory)}
                className='filter-header-arrow-icon'
                alt='arrow-icon'
                src={IconArrowDown}
                style={openCategories[filterCategory] ? {} : { transform: 'rotate(180deg)' }}
              />
            </div>
            <div
              className={
                openCategories[filterCategory]
                  ? 'filter-subcategories-container open'
                  : 'filter-subcategories-container closed'
              }
            >
              {filters[filterCategory].map((subcategory) => {
                return (
                  <div
                    className='filter-subcategory-container'
                    onClick={() => onSelectFilter(filterCategory, subcategory)}
                    key={subcategory}
                  >
                    <span className='checkbox-container'>
                      <img
                        className='checkbox'
                        alt='checkbox'
                        src={IconCheckbox}
                        style={tempSelectedFilters[filterCategory].includes(subcategory) ? {} : { opacity: '0' }}
                      />
                    </span>
                    <span className='subcategory-text'>{subcategory}</span>
                    <span className='filter-count-tag'>
                      {filterCounts[filterCategory]
                        ? filterCounts[filterCategory][subcategory]
                          ? filterCounts[filterCategory][subcategory]
                          : 0
                        : null}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        )
      }
      return null
    })
  }

  return (
    <div>
      <div className='tablet-filter-container'>
        <div className='tablet-filter-controller' onClick={() => setFilterMenuOpenAndSendGAEvent()}>
          <span className='tablet-filter-controller-text'>
            <span className='tablet-filter-controller-header'>Filter</span>
          </span>
          <div className='tablet-filter-controller-right'>
            <img className='filter-container-arrow-icon' src={IconArrowDown} alt='arrow-icon' />
          </div>
        </div>
        <div className='tablet-filter-menu-container' style={filterMenuOpen ? {} : { transform: 'translateX(-100%)' }}>
          <div className='filter-header'>
            <span>Filters</span>
            <img
              className='tablet-icon-cancel-small'
              onClick={() => setFilterMenuOpen(false)}
              src={IconCancelSmall}
              alt='cancel-icon'
            />
          </div>
          {renderCategories()}
          <div className='filter-menu-bottom-controllers'>
            <button className='tablet-filter-menu-clear-button' onClick={clearFilters}>
              Clear
            </button>
            <button className='tablet-filter-menu-apply-button' onClick={applyFilters}>
              Apply
            </button>
          </div>
        </div>
        <div className='tablet-filter-menu-backdrop' style={filterMenuOpen ? {} : { display: 'none' }} />
      </div>

      <div className='mobile-filter-container'>
        <div className='tablet-filter-controller' onClick={closeHeaderAndToggleFilter}>
          <div className='tablet-filter-controller-right'>
            <img className='mobile-filter-container-icon' src={MobileFilterIcon} alt='arrow-icon' />
          </div>
          <span className='tablet-filter-controller-text'>Filter</span>
        </div>
        <div className='tablet-filter-menu-container' style={filterMenuOpen ? {} : { transform: 'translateX(-100%)' }}>
          <div className='filter-header'>
            <span>Filters</span>
            <img
              className='tablet-icon-cancel-small'
              onClick={closeFilterAndHeader}
              src={IconCancelSmall}
              alt='cancel-icon'
            />
          </div>
          {renderCategories()}
          <div className='filter-menu-bottom-controllers'>
            <button className='tablet-filter-menu-clear-button' onClick={clearFilters}>
              Clear
            </button>
            <button className='tablet-filter-menu-apply-button' onClick={applyFilters}>
              Apply
            </button>
          </div>
        </div>
        <div className='tablet-filter-menu-backdrop' style={filterMenuOpen ? {} : { display: 'none' }} />
      </div>
    </div>
  )
}
