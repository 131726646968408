import React, { Component } from 'react'
import debug from 'debug'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  setNearMePicked,
  setNearMeLatitude,
  setNearMeLongitude,
  setLocationSearchItems,
  startLocationSearch
} from '../../../redux/slices'
import Downshift from 'downshift'
import { loadLocationItems } from '../../../util/searchArgs'
import { CarlinPropTypes } from '../../../entities'
import { El, FlexEl } from '../../layout'
import { Location, Plane } from '../../svg'
import { connect } from 'react-redux'
import { func, string } from 'prop-types'
import { MenuPlaceholderDS, ModalInputDS, SelectDS } from '../../CarlaSelect'
import Loading from '../../Loading'
import debounce from 'lodash/debounce'
import trim from 'lodash/trim'
import NoLocationFoundImg from '../../../util/images/icon-lamb.svg'
import LocationIcon from '../../../util/images/icon-location-oneway-pickup.svg'
import { IoIosArrowBack } from 'react-icons/io'
import API from '../../../services/API'
import { idDataMap } from '../../../util/dynamicLandingPageData'

const log = debug('carla:search')

const NoLocationFoundImage = styled.img`
  display: block;
  height: 120px;
  width: auto;
  margin: 0px auto;
  margin-bottom: 30px;
  margin-top: -40px;
`

const NoLocationFoundText = styled.div`
  width: 60%;
  font-size: 25px;
  margin: 0 auto;
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${themeGet('colors.textBlack')};
`

const InputArea = styled(SelectDS)`
  width: 90% !important;
  border: none !important;
  text-align: left;
  padding-left: 0;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`

const InputAreaWrapper = styled.div`
  width: 90%;
  height: 50px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 10px;
  background: url(${LocationIcon});
  background-repeat: no-repeat;
  background-color: white;
  background-position: 1.5% 50%;
  border: ${(props) => (props.isPageDynamic ? '1px solid #dfdfe7' : 'none')};

  // mobile location input shadow below
  -webkit-box-shadow: ${(props) =>
    props.isPageDynamic ? '2px 4px 14px 0px rgba(65,64,66,0.15)' : '0px 4px 4px 0px rgba(0,0,0,0.25)'};
  -moz-box-shadow: ${(props) =>
    props.isPageDynamic ? '2px 4px 14px 0px rgba(65,64,66,0.15)' : '0px 4px 4px 0px rgba(0,0,0,0.25)'};
  box-shadow: ${(props) =>
    props.isPageDynamic ? '2px 4px 14px 0px rgba(65,64,66,0.15)' : '0px 4px 4px 0px rgba(0,0,0,0.25)'};
`

const LocationPopUpContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LocationPopUpWrapper = styled.div`
  width: 90vw;
  height: 100vh;
  background: white;
  overflow: auto;
  margin: 0 auto;
  margin-top: 8vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const PopularLocationsTitle = styled.div`
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: ${themeGet('colors.colorPrimary')};
  margin: 20px;
  margin-bottom: 10px;
`

const LocationSelectContainer = styled(El)`
  width: 100%;
  min-height: 50px;
`

const Explanation = styled.div`
  padding-left: 35px;
`

const LocationModalInputContainer = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  height: 150px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 60px;
  padding-bottom: 8px;
  box-shadow: 0px -4px 8px 9px rgba(0, 0, 0, 0.12);
`

const InputClearButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 102px;
  right: 4px;
  color: #abb5d9;
  font-size: 30px;
  font-weight: 500;
`

const ElipsedModalInputDS = styled(ModalInputDS)`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`

const RawLocationSelect = styled(
  class extends Component {
    constructor(props) {
      super(props)
      this.state = {
        inputValue: ' ',
        popularLocations: null,
        isLocationPopUpOpen: false,
        showNoLocationFound: true
      }
      this.inputRef = React.createRef(null)
    }

    static propTypes = {
      value: CarlinPropTypes.SelectItem,
      onChange: func.isRequired,
      label: string.isRequired
    }

    componentDidUpdate = () => {
      if (!this.inputRef.current) return
      if (!this.state.isLocationPopUpOpen) {
        this.inputRef.current.blur()
        return
      }
      this.inputRef.current.focus()
    }

    getPopularLocations = async () => {
      const response = await API.getPopularLocations()
      this.setState({
        popularLocations:
          response &&
          response.map((item) => {
            const locationObj = { value: item.id, label: item.prettyName, type: item.type }
            return locationObj
          })
      })
    }

    getDynamicPopularLocations = async () => {
      const url = window.location.pathname
      const id = idDataMap[url].id
      const response = await API.getDynamicPopularLocations(id)
      this.setState({
        popularLocations:
          response &&
          response.map((item) => {
            const locationObj = { value: item.id, label: item.prettyName, type: item.type }
            return locationObj
          })
      })
    }

    // call item loading functions with 300ms delay / @TODO DRY
    requestAPI = debounce((input) => {
      if (!input || !input.trim()) return
      this.props.startLocationSearch()
      loadLocationItems(input)
        .then((items) => {
          if (items && items.length > 0) {
            this.props.setLocationSearchItems(items)
          } else {
            log('no location found', items)
            this.props.setLocationSearchItems(null)
          }
        })
        .catch((err) => {
          log('error during car search', err)
          this.props.setLocationSearchItems(null)
        })
      this.setState({ showNoLocationFound: true })
    }, 300)

    // @TODO DRY
    handleInputChange = (inputValue) => {
      this.setState({ inputValue: inputValue, showNoLocationFound: false })
      const input = trim(inputValue)
      this.requestAPI(input)
    }

    // @TODO DRY
    getLocationInput = (inputValue) => {
      if (!this.props.compareIfSameWith) return inputValue
      if (this.props.value && this.props.value.label !== inputValue) return inputValue
      const { value, compareIfSameWith } = this.props

      if (value.label === compareIfSameWith.label) {
        return 'Same Location'
      }
      return inputValue
    }

    handleNext = (e) => {
      e.preventDefault()

      this.setState({ isLocationPopUpOpen: false, inputValue: ' ' })
      const mobileSearchForm = document.getElementById('MOBILE-SEARCH-FORM')

      if (mobileSearchForm.classList.contains('FLOWACTIVE')) {
        if (this.props.isSameLocation === false) {
          const dropOffInput = document.getElementById('DropOffInput')
          setTimeout(() => {
            dropOffInput.click()
          }, 500)
        } else {
          const dateInput = document.getElementById('DateInput')
          setTimeout(() => {
            dateInput.click()
          }, 500)
        }
      }
    }

    handleFlowCancel = (e) => {
      e.preventDefault()
      this.setState({ isLocationPopUpOpen: false })
      const mobileSearchForm = document.getElementById('MOBILE-SEARCH-FORM')
      mobileSearchForm.classList.remove('FLOWACTIVE')
    }

    handleClearInput = (e) => {
      e.preventDefault()
      this.handleInputChange('')
    }

    renderModalContent = ({ selectedItem, PopularLocations, isLoading, error, items, getItemProps, optimize }) => (
      <div onClick={this.handleNext}>
        <El style={{ marginTop: '150px', minHeight: '95vh' }}>
          {isLoading && (
            <MenuPlaceholderDS>
              <Loading />
            </MenuPlaceholderDS>
          )}
          {this.state.showNoLocationFound &&
            (PopularLocations &&
            !isLoading &&
            (this.state.inputValue === '' ||
              this.state.inputValue === ' ' ||
              (selectedItem && this.state.inputValue === selectedItem.label)) ? (
              <div>
                {/*
                            İf u wanna get back "Near Me" you can use code
                            <FindCarsNearMe {...getItemProps({ key: 'findcarsnearme', item: { label: `Finding cars near you`, value: 999 }, index: '999' })} className="find-cars-near-me">
                                <img src={iconNearme} alt="" />
                                <p>Find cars near me</p>
                            </FindCarsNearMe>
                            */}
                <PopularLocationsTitle>Popular Locations</PopularLocationsTitle>
                <ul className='items popularlocations'>
                  {PopularLocations.map((item, index) => (
                    <FlexEl
                      justifyContent='flex-start'
                      as='li'
                      className='item'
                      {...getItemProps({ key: item.value, item, index })}
                    >
                      <El>{item.type === 'airport' ? <Plane /> : <Location />}</El>
                      <El style={{ textAlign: 'left' }}>{item.label}</El>
                    </FlexEl>
                  ))}
                </ul>
              </div>
            ) : items && items.length > 0 ? (
              <ul className='items'>
                {items.map((item, index) => (
                  <FlexEl
                    justifyContent='flex-start'
                    as='li'
                    className='item'
                    {...getItemProps({ key: item.value, item, index })}
                  >
                    <El>{item.type === 'airport' ? <Plane /> : <Location />}</El>
                    <El style={{ textAlign: 'left' }}>{item.label}</El>
                  </FlexEl>
                ))}
              </ul>
            ) : (
              !isLoading &&
              !this.state.inputValue && (
                <MenuPlaceholderDS>
                  <NoLocationFoundImage src={NoLocationFoundImg} />
                  <NoLocationFoundText>
                    We couldn't find the location you are searching. Try entering a city or an airport.
                  </NoLocationFoundText>
                </MenuPlaceholderDS>
              )
            ))}
        </El>
      </div>
    )

    handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault()
      }
    }

    render() {
      const {
        idForClick,
        openPopUp,
        setPopUpOpen,
        explanation,
        value,
        label,
        onChange,
        items,
        isLoading,
        error,
        optimize,
        ...props
      } = this.props

      if (!this.state.popularLocations) {
        if (this.props.isPageDynamic) this.getDynamicPopularLocations()
        else this.getPopularLocations()
      }
      const PopularLocations = this.state.popularLocations

      if (this.state.isLocationPopUpOpen) {
        document.body.style.overflow = 'hidden'
        const x = window.scrollX
        const y = window.scrollY
        window.onscroll = function () {
          window.scrollTo(x, y)
        }
      }

      if (!this.state.isLocationPopUpOpen) {
        document.body.style.removeProperty('overflow')
        window.onscroll = function () {}
      }

      return (
        <Downshift
          onChange={onChange}
          items={items}
          itemToString={(item) => (item ? item.label : '')}
          selectedItem={value}
          {...props}
        >
          {({
            getLabelProps,
            getRootProps,
            getInputProps,
            getToggleButtonProps,
            getMenuProps,
            getItemProps,
            inputValue,
            isOpen,
            selectedItem,
            closeMenu
          }) => {
            return (
              <LocationSelectContainer {...getRootProps()} {...props}>
                <InputAreaWrapper
                  isPageDynamic={this.props.isPageDynamic}
                  id={idForClick ? idForClick : 'PickUpInput'}
                  onClick={() => {
                    this.setState({ isLocationPopUpOpen: true })
                  }}
                >
                  <InputArea type='button' {...getToggleButtonProps()}>
                    {selectedItem ? this.getLocationInput(selectedItem.label) : 'Enter city or airport'}
                  </InputArea>
                </InputAreaWrapper>
                <LocationPopUpContainer
                  style={{
                    transition: 'all 0.2s ease-in-out',
                    transform: this.state.isLocationPopUpOpen ? '' : 'scale(0)',
                    zIndex: '10010'
                  }}
                >
                  <LocationPopUpWrapper>
                    <LocationModalInputContainer>
                      {explanation && <Explanation>{explanation}</Explanation>}
                      <FlexEl
                        style={{ width: '100%' }}
                        className='back-icon'
                        justifyContent='center'
                        alignItems='center'
                      >
                        <IoIosArrowBack size={24} onClick={this.handleFlowCancel} />
                        <ElipsedModalInputDS
                          innerRef={this.inputRef}
                          placeholder='Enter city or airport'
                          value={
                            this.state.inputValue === ' '
                              ? selectedItem !== null
                                ? selectedItem.label
                                : ''
                              : this.state.inputValue
                          }
                          onChange={(event) => this.handleInputChange(event.target.value)}
                          onKeyPress={this.handleKeyPress}
                        />
                      </FlexEl>
                      {this.state.inputValue && <InputClearButton onClick={this.handleClearInput}>X</InputClearButton>}
                    </LocationModalInputContainer>
                    {this.renderModalContent({
                      selectedItem,
                      PopularLocations,
                      isLoading,
                      error,
                      items,
                      getItemProps,
                      optimize
                    })}
                  </LocationPopUpWrapper>
                </LocationPopUpContainer>
              </LocationSelectContainer>
            )
          }}
        </Downshift>
      )
    }
  }
)`
  .items {
    margin: 0;
    .item {
      display:flex;
      flex-wrap: nowrap;
      justify-content:flex-start;
      align-items:center;
      gap: 10px;
      height: 60px;
      padding: 10px 15px;
      .location { fill: ${themeGet('colors.blueGrey.6')};
      .plane { fill: ${themeGet('colors.blueGrey.6')}};
    }
  }
`

const LocationSelect = connect(
  (state) => ({ optimize: state.optimize }), // deciding to show near me locations feature
  { startLocationSearch, setLocationSearchItems, setNearMeLatitude, setNearMeLongitude, setNearMePicked }
)(RawLocationSelect)

export default LocationSelect
