import React from 'react'
import { connect } from 'react-redux'
import { removeReservationInfoResponse, setReservationInfoResponse } from '../../redux/slices'
import ReservationTable from './ReservationTable'
import { PageContainer } from '../../components/layout'
import styled from 'styled-components'
import useReservation from '../../hooks/useReservation'

const PageContent = styled('div')`
  width: 90%;
  margin: 80px auto 20px;
`

const RawReservations = () => {
  const { data } = useReservation()
  return (
    <PageContainer>
      <PageContent>
        <ReservationTable rows={data} />
      </PageContent>
    </PageContainer>
  )
}

const Reservations = connect(
  (state) => {
    return {
      reservationInfoRetrieval: state.reservationInfoRetrieval
    }
  },
  { removeReservationInfoResponse, setReservationInfoResponse }
)(RawReservations)

export default Reservations
