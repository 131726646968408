import { createGlobalStyle } from 'styled-components'
import { themeGet } from 'styled-system'

const none = 'rgb(0,0,0,0)'
const silver = '#F0F1F3'
const greyPale = '#cdcdcd'
const grey = '#353535'

/**
 * @see https://material.io/design/color/the-color-system.html#tools-for-picking-colors
 */
const gray = [
  '#FAFAFA',
  '#F5F5F5',
  '#EEEEEE',
  '#E0E0E0',
  '#BDBDBD',
  '#9E9E9E',
  '#757575',
  '#616161',
  '#424242',
  '#212121'
]
const blueGrey = [
  '#EFF1F8',
  '#CFD8DC',
  '#B0BEC5',
  '#B4B4C2',
  '#78909C',
  '#696984',
  '#546E7A',
  '#455A64',
  '#37474F',
  '#525266'
]
const teal = [
  '#E0F2F1',
  '#B2DFDB',
  '#80CBC4',
  '#4DB6AC',
  '#26A69A',
  '#009688',
  '#00897B',
  '#00796B',
  '#00695C',
  '#004D40'
]
const yellow = [
  '#FFFDE7',
  '#FFF9C4',
  '#FFF59D',
  '#FFF176',
  '#FFEE58',
  '#FFEB3B',
  '#FDD835',
  '#FBC02D',
  '#F9A825',
  '#F57F17'
]
const amber = [
  '#FFF8E1',
  '#FFECB3',
  '#FFE082',
  '#FFD54F',
  '#FFCA28',
  '#FFC107',
  '#FFB300',
  '#FFA000',
  '#FF8F00',
  '#FF6F00'
]
const orange = [
  '#FFF3E0',
  '#FFE0B2',
  '#FFCC80',
  '#FFB74D',
  '#FFA726',
  '#FF9800',
  '#FB8C00',
  '#F57C00',
  '#EF6C00',
  '#E65100'
]
const deepOrange = [
  '#FBE9E7',
  '#FFCCBC',
  '#FFAB91',
  '#FF8A65',
  '#FF7043',
  '#FF5722',
  '#FF7D0E',
  '#E64A19',
  '#D84315',
  '#BF360C'
]
const red = [
  '#FFEBEE',
  '#FFCDD2',
  '#EF9A9A',
  '#E57373',
  '#EF5350',
  '#F44336',
  '#E53935',
  '#D32F2F',
  '#C62828',
  '#B71C1C'
]
const olive = [
  '#F1F8E9',
  '#DCEDC8',
  '#C5E1A5',
  '#AED581',
  '#9CCC65',
  '#8BC34A',
  '#7CB342',
  '#689F38',
  '#558B2F',
  '#33691E'
] // light green
const green = [
  '#E8F5E9',
  '#C8E6C9',
  '#A5D6A7',
  '#81C784',
  '#66BB6A',
  '#4CAF50',
  '#43A047',
  '#388E3C',
  '#2E7D32',
  '#1B5E20'
]
const battleshipGray = ['#525266']

const colorPrimary = '#FF7D0E'
const newColorPrimary = '#EFF1F8'
const textBlack = '#525266'
const textGrey = '#696984'
const separatorGrey = '#DFDFE7'
const separatorBlue = '#0076FF'
const colorHint = '#B4B4C2'

export const colors = {
  none,
  silver,
  grey,
  greyPale,
  yellow,
  amber,
  orange,
  deepOrange,
  red,
  gray,
  blueGrey,
  teal,
  olive,
  battleshipGray,
  green,
  colorPrimary,
  textBlack,
  textGrey,
  separatorGrey,
  colorHint,
  newColorPrimary,
  separatorBlue
}
export const breakpoints = [576, 1200]
// export const newBreakPoints = [576, 1024, 1200]
export const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 72]
export const space = [0, 4, 8, 16, 32, 64]

export const theme = {
  colors,
  breakpoints,
  fontSizes,
  space,
  navbar: {
    height: [50, 50, 80]
  },
  footer: {
    height: 60
  },
  textStyles: {
    nav: {
      fontSize: fontSizes[0],
      fontWeight: 'normal',
      whiteSpace: 'nowrap',
      textTransform: 'uppercase',
      textDecoration: 'none'
    }
  },
  buttons: {
    outline: {
      color: grey,
      backgroundColor: none,
      border: `1px solid ${teal[5]}`,
      transition: 'color 250ms ease',
      ':hover': {
        color: teal[5]
      }
    }
  }
}

export const GlobalStyle = createGlobalStyle`
:root {
  --colorPrimary: #FF7D0E;
  --newColorPrimary: #EFF1F8;
  --textBlack: #525266;
  --textGrey: #696984;
  --separatorGrey: #DFDFE7;
  --colorHint: #B4B4C2;
  --borderGrey: #dfdfe7;
  --passive-text: #b4b4c2;
}

  *, body, button, input, textarea, select {
    box-sizing: border-box;
    font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  }
  body {
    margin: 0;
    padding: 0;
    background: #EFF1F8;
  }
  a, button {
    display: block;
  }
  input {
   // override legacy safari input defaults
   -webkit-appearance: none;
  }
  ul {
    padding: 0;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
    font-display: swap;
  }

  .overflow-hidden {
    overflow: hidden;
  }
  .text-center {
    text-align: center;
  }

  // input addons should fit
  .form-field {
    position: relative;
  }

  // react-toastify overwritten styles (expand length)
  .Toastify__toast-container {
    width: 500px;
    margin-left: -250px;
    .Toastify__toast-container--bottom-center {
      margin-left: -250px;
    }
    .Toastify__toast--error {
      background: ${themeGet('colors.red.4')} linear-gradient(45deg, #dd1818, #9C1212);
      &:hover {
        background-color: ${themeGet('colors.red.5')};

      }
    }
  }
  @media only screen and (max-width: 480px) {
    .Toastify__toast-container {
      width: 100vw;
      margin-left: 0;
      .Toastify__toast-container--bottom-center {
        margin-left: 0;
      }
    }
  }

  iframe#drift-widget.drift-widget-welcome-online,
  iframe#drift-widget.drift-widget-welcome-expanded-online {
    display: none !important;
    right: 10px !important;
    bottom: 4px !important;
  }
  #drift-frame-chat, #drift-frame-controller {
    z-index: 2147483646 !important;
    display: none !important;
  }
  #portal-root {
    display: none !important;
    position: relative;
    z-index: 2147483647 !important;
  }
`
