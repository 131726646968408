import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { MdPerson } from 'react-icons/md'

export const IconStyled = styled(MdPerson)`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-12px);
  width: 24px;
  font-size: 24px;
  color: var(--textBlack);
`
export const ImageStyled = styled('img')`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-9px);
  width: 24px;
  font-size: 24px;
  color: var(--textBlack);
`
export const TextInput = styled('input')`
  width: 100%;
  padding-left: 42px;
  border-radius: 0;
  font-size: 16px;
  border: none;
  background: #fff;
  font-weight: 500;
  height: auto;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: white;
  color: ${themeGet('colors.textBlack')};
  border-radius: 0;
  border: solid 2px transparent;
  outline: none;
  overflow: hidden;
  border-top-right-radius: ${(props) => `${props.$rightRadius}px` || 0};
  border-bottom-right-radius: ${(props) => `${props.$rightRadius}px` || 0};
  border-top-left-radius: ${(props) => `${props.$leftRadius}px` || 0};
  border-bottom-left-radius: ${(props) => `${props.$leftRadius}px` || 0};

  &::placeholder {
    transition: all 100ms ease;
    color: #b4b4c2;
  }
  &:focus,
  &.focus,
  .focus > & {
    object-fit: contain;
    border: solid 2px ${themeGet('colors.colorPrimary')};
    color: ${themeGet('colors.textBlack')};
    &::placeholder {
      color: #b4b4c2;
    }
  }
`

export const GroupTitle = styled('label')`
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
  font-weight: 600;
`
export const PickUpLocationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-end;
  margin-top: 0px;
  border-right: none;
`
