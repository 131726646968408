import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  carType: [],
  fuel: [],
  transmission: [],
  seats: [],
  cancellationPolicy: [],
  supplier: [],
  broker: [],
  fullyPrepaid: []
}

export const carSearchFiltersSlice = createSlice({
  name: 'carSearchFilters',
  initialState,
  reducers: {
    setCarSearchFilters: (state, action) => {
      state = action.payload
      return state
    }
  }
})

export const { setCarSearchFilters } = carSearchFiltersSlice.actions

export default carSearchFiltersSlice.reducer
