import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { LoadingModal } from './../components/Modal'

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated, isLoading } = useAuth0()

  if (isLoading) return <LoadingModal />

  return (
    <Route
      {...rest}
      render={() => {
        return isAuthenticated === true ? children : <Redirect to='/login' />
      }}
    />
  )
}
export default PrivateRoute
