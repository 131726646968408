import { Box, Flex, Link } from 'rebass'
import React from 'react'
import styled from 'styled-components'
import { style, themeGet } from 'styled-system'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

/*******************************
 * EXTEND STYLED-SYSTEM STYLES *
 *******************************/

const show = style({
  prop: 'show',
  cssProperty: 'display',
  transformValue: (val) => (val ? 'block' : 'none')
})

const showFlex = style({
  prop: 'show',
  cssProperty: 'display',
  transformValue: (val) => (val ? 'flex' : 'none')
})

const height = style({
  prop: 'height',
  cssProperty: 'height',
  transformValue: (val) => (val <= 1 ? `${val * 100}%` : `${val}px`)
})

const flexGrow = style({
  prop: 'flexGrow',
  cssProperty: 'flexGrow'
})

const flexWrap = style({
  prop: 'flexWrap',
  cssProperty: 'flexWrap'
})

const alignItems = style({
  prop: 'alignItems',
  cssProperty: 'alignItems'
})

const fontWeight = style({
  prop: 'fontWeight',
  cssProperty: 'fontWeight'
})

const fontSize = style({
  prop: 'fontSize',
  cssProperty: 'fontSize'
})

// const border = style({
//   prop: 'border',
//   cssProperty: 'border'
// })
//
// const boxShadow = style({
//   prop: 'boxShadow',
//   cssProperty: 'boxShadow'
// })

/**********************
 * YERLI MILLI REBASS *
 **********************/

export const El = styled(Box)`
  &:focus {
    outline: none;
  }
  ${show}
  ${flexGrow}
  ${flexWrap}
  ${height}
  ${fontWeight}
  ${fontSize}
`

export const FlexEl = styled(Flex)`
  &:focus {
    outline: none;
  }
  ${showFlex}
  ${flexGrow}
  ${flexWrap}
  ${alignItems}
  ${height}
  ${fontWeight}
  ${fontSize}
`

export const ButtonEl = styled((props) => <El as='button' {...props} />)`
  border: none;
  text-decoration: none;
  transition: background-color 100ms, color 100ms ease;
  .icon {
    transition: stroke 100ms;
  }
  &:hover {
    cursor: pointer;
  }
  &:hover,
  &:focus {
    outline: none;
  }
  ${show}
  ${flexGrow}
  ${flexWrap}
  ${height}
  ${fontWeight}
  ${fontSize}
`

export const LinkEl = styled(Link)`
  &:hover {
    cursor: pointer;
  }
  ${show}
  ${flexGrow}
  ${flexWrap}
  ${height}
  ${fontWeight}
  ${fontSize}
`

const styles = {
  root: {
    variant: 'outlined',
    color: '#ff7d0e',
    marginTop: 20,
    [`& fieldset`]: {
      borderRadius: 8,
      borderColor: '#dfdfe7'
    },
    '& label.Mui-focused': {
      color: '#ff7d0e'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'orange'
    },
    '& .MuiOutlinedInput-root': {
      fontFamily: ['Quicksand, Quicksand Regular, sans-serif'],
      '& fieldset': {
        borderColor: '#dfdfe7'
      },
      '&:hover fieldset': {
        borderColor: '#ff7d0e'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ff7d0e'
      }
    }
  },
  input: {
    color: '#525266',
    fontFamily: 'Quicksand, Quicksand Regular, sans-serif',
    fontSize: 16,
    fontWeight: 500,
    borderRadius: 8
  },
  label: {
    color: '#b4b4c2',
    fontFamily: 'Quicksand, Quicksand Regular, sans-serif',
    fontSize: 15,
    fontWeight: 500
  }
}

function TextView(props) {
  const { classes, endAdornment, minLength, maxLength, inputRef, ...rest } = props
  return (
    <TextField
      {...rest}
      fullWidth
      inputRef={inputRef}
      variant='outlined'
      className={classes.root}
      InputProps={{
        className: classes.input,
        endAdornment: endAdornment,
        minLength: minLength,
        maxLength: maxLength
      }}
      InputLabelProps={{
        className: classes.label
      }}
    />
  )
}

const TextViewRef = React.forwardRef((props, ref) => <TextView {...props} inputRef={ref} />)

export default withStyles(styles)(TextViewRef)

export const InputEl = styled(({ innerRef, placeholder, ...props }) => (
  <Box as='input' placeholder={placeholder} ref={innerRef} {...props} />
))`
  ${show}
  ${flexGrow}
  ${flexWrap}
  ${height}
  ${fontWeight}
  ${fontSize}
  height: 50px;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid ${themeGet('colors.blueGrey.2')};
  font-size: 16px;
  transition: all 100ms ease;
  color: ${themeGet('colors.textBlack')};
  &::placeholder {
    transition: all 100ms ease;
    color: ${themeGet('colors.blueGrey.3')};
  }
  &:hover {
    color: ${themeGet('colors.textBlack')};
    &::placeholder {
      color: ${themeGet('colors.textGrey')};
    }
  }
  &:focus {
    color: ${themeGet('colors.textBlack')};
    box-shadow: 0 0 0 -1px ${themeGet('colors.teal.4')};
    border-color: ${themeGet('colors.teal.4')};
    outline: none;
    &::placeholder {
      color: ${themeGet('colors.blueGrey.7')};
    }
  }
`

export const HrEl = styled(({ color, ...props }) => <El as='hr' {...props} />)`
  border: 1px solid ${(props) => props.color || themeGet('colors.blueGrey.2')};
`

/*********************
 * CUSTOM COMPONENTS *
 *********************/

// element covering whole viewport between navbar and footer
export const PageContainer = styled(El)`
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  // viewport - navbar - footer
  min-height: calc(100vh - ${themeGet('navbar.height.0')}px - ${themeGet('footer.height')}px);
  @media screen and (min-width: ${themeGet('breakpoints.0')}px) {
    // viewport - navbar - footer
    min-height: calc(100vh - ${themeGet('navbar.height.1')}px - ${themeGet('footer.height')}px);
  }
`

// like container of bootstrap, use it when needed
export const Container = styled(El)`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: ${themeGet('breakpoints.1')}px) {
    width: ${themeGet('breakpoints.1')}px;
  }
`

export const CardEl = styled(El)`
  background-color: white;
  border-radius: 12px;
  border: solid 1px #dfdfe7;
`

export const CardFlexEl = (props) => <CardEl as={FlexEl} {...props} />

export const PrimaryButton = styled(ButtonEl)`
  color: white;
  padding: ${themeGet('space.2')}px ${themeGet('space.4')}px;
  font-size: 14px;
  transition: background-color 100ms ease;
  background-color: ${themeGet('colors.teal.6')};
  :hover,
  &:focus {
    background-color: ${themeGet('colors.teal.7')};
  }
  &:active {
    background-color: ${themeGet('colors.teal.8')};
  }
`

export const GreenButton = styled(ButtonEl)`
  font-weight: bold;
  border-bottom: 1px solid;
  background-color: ${themeGet('colors.green.6')};
  border-color: ${themeGet('colors.green.8')};
  color: white;
  letter-spacing: 1px;
  border-radius: 3px;
  text-transform: uppercase;
  .icon {
    stroke: white;
  }
  &:hover,
  &:focus {
    background-color: ${themeGet('colors.green.8')};
  }
  &:active {
    background-color: ${themeGet('colors.green.9')};
  }
`

export const SearchButton = styled(ButtonEl)`
  font-size: 18px;
  background-color: ${themeGet('colors.colorPrimary')};
  color: white;
  border-radius: 12px;
  text-transform: uppercase;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  .icon {
    stroke: white;
  }
  &:hover,
  &:focus {
    background-color: #e86f09;
  }
  &:active {
    background-color: ${themeGet('colors.deepOrange.7')};
  }
`

export const DirectButton = styled(ButtonEl)`
  font-size: 18px;
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-weight: bold;
  color: white;
  background-color: ${themeGet('colors.colorPrimary')};
  border-radius: 4px;
  padding: 9px 0 9px 0;
  width: 100%;
`

export const GoogleButton = styled(ButtonEl)`
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #75757f;
  background-color: white;
  border-radius: 22px;
  border: 1px solid ${themeGet('colors.separatorGrey')};
  padding: 12px 24px 12px 24px;
  width: 100%;
  text-align: center;
  position: relative;
  margin-top: 16px;
  > img {
    position: absolute;
    left: 20px;
  }
  > span {
    margin: auto;
  }
`

export const XO1NextButton = styled(ButtonEl)`
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  color: white;
  background-color: ${themeGet('colors.colorPrimary')};
  &:hover,
  &:focus {
    background-color: #e86f09;
  }
  &:active {
    background-color: ${themeGet('colors.deepOrange.7')};
  }
  @media (hover: none) {
    background-color: #e86f09;
  }
`

export const OrangeButton = styled(ButtonEl)`
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  color: white;
  background-color: ${themeGet('colors.colorPrimary')};
  &:hover,
  &:focus {
    background-color: #e86f09;
  }
  &:active {
    background-color: ${themeGet('colors.deepOrange.7')};
  }
`

export const BlueGreyButton = styled(ButtonEl)`
  color: white;
  border-bottom: 1px solid;
  font-weight: bold;
  background-color: ${themeGet('colors.textGrey')};
  border-color: ${themeGet('colors.blueGrey.8')};
  letter-spacing: 1px;
  border-radius: 3px;
  text-transform: uppercase;
  &:hover,
  &:focus {
    background-color: ${themeGet('colors.blueGrey.6')};
  }
  &:active {
    background-color: ${themeGet('colors.blueGrey.7')};
  }
`

export const BlueGreyLink = styled(LinkEl)`
  color: ${themeGet('colors.blueGrey.7')};
  &:hover,
  &:focus {
    color: ${themeGet('colors.textBlack')};
  }
`

export const OrangeLink = styled(LinkEl)`
  color: ${themeGet('colors.colorPrimary')};
  &:hover,
  &:focus {
    color: ${themeGet('colors.colorPrimary')};
  }
`
export const PageMainContainer = styled(PageContainer).attrs({
  as: 'main'
})``
