import { useQuery } from 'react-query'
import API from '../services/API'

export default function usePopularLocations(bundleId) {
  const { data, isLoading } = useQuery(['popular-location', bundleId], () => API.getPopularLocations(), {
    keepPreviousData: true,
    placeholderData: []
  })

  return {
    data: data?.map((location) => ({
      value: location.id,
      label: location.prettyName,
      type: location.type
    })),
    isLoading
  }
}
