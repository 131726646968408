import React, { Component } from 'react'
import debug from 'debug'
import { connect } from 'react-redux'
import util from '../../../util'
import { toast } from 'react-toastify'
import { setSearchPickupLocation } from '../../../redux/slices'
import { selectSearchArgs } from '../../../redux/selectors'
import { checkDefaultDateRange } from '../../../util/searchArgs'
import API from '../../../services/API'
import MobileSearch from './../../SearchBarHome/MobileSearch'
import SearchBarDesktop from './SearchBarDesktop'
import { DesktopScreen, DefaultScreen } from './../../common/Screens'

const log = debug('carla:search')

class Search extends Component {
  getClosestLocation = async () => {
    try {
      log('fetching closest location')
      const closestLocation = await API.getClosestLocation()
      if (closestLocation) {
        const location = {
          label: closestLocation.prettyName,
          value: closestLocation.id,
          type: closestLocation.type
        }
        log('closest location:', closestLocation)
        this.props.setSearchPickupLocation(location)
      } else {
        log('empty response from closestLocation', closestLocation)
      }
    } catch (err) {
      log('closest location not found', err)
    }
  }

  componentDidMount() {
    checkDefaultDateRange()
  }

  /**
   * validates search form, returns true if error found, false otherwise
   * @returns {string|boolean}
   */
  validateCarSearchInfo = () => {
    if (!this.props.pickupLocation) {
      return 'Please enter pickup location'
    }

    const { pickupDateTime, dropOffDateTime } = this.props
    if (!pickupDateTime || !dropOffDateTime) {
      return 'Please select pick-up and drop-off date'
    }
    // if pickup is after from
    if (pickupDateTime > dropOffDateTime) {
      return 'Please select drop-off time after pick-up time'
    }
    // if duration less then 3 hours
    if (dropOffDateTime.getTime() - pickupDateTime.getTime() < 1000 * 60 * 30) {
      return 'Please select drop-off time at least 30 minutes after pick-up time'
    }

    return false
  }

  /**
   * submit search
   * @param e {event}
   */
  handleSubmit = async (e) => {
    e.preventDefault()

    // handle error
    const error = this.validateCarSearchInfo()
    if (error) {
      if (!toast.isActive(this.errorToastId)) {
        this.errorToastId = toast.error(error)
      }
      return
    }

    const { pickupLocation, dropOffLocation, pickupDateTime, dropOffDateTime, age } = this.props
    const pickupTimeStr = util.formatDateForAPI(pickupDateTime)
    const dropOffTimeStr = util.formatDateForAPI(dropOffDateTime)

    // search
    try {
      let url = `/rental-cars/${pickupLocation.value}/${dropOffLocation.value}/${pickupTimeStr}/${dropOffTimeStr}`
      if (age && Number(age) < 25) url += `?age=${age}`
      log('redirecting to search page:', url)
      this.props.history.push(url)
    } catch (err) {
      console.error('error while submitting search form', err)
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* DESKTOP SEARCH BAR */}
        <DesktopScreen>
          <SearchBarDesktop age={this.props.age} handleSubmit={this.handleSubmit} />
        </DesktopScreen>
        {/* MOBILE AND TABLET SEARCH BAR */}
        <DefaultScreen>
          <MobileSearch handleSubmit={this.handleSubmit} />
        </DefaultScreen>
      </React.Fragment>
    )
  }
}

export default connect(selectSearchArgs, { setSearchPickupLocation })(Search)
