import React from 'react'
import loadable from '@loadable/component'

import 'react-day-picker/lib/style.css'

const Loader = loadable.lib(() => import('react-day-picker'))

function ReactDayPicker(props) {
  return <Loader fallback={null}>{({ default: DayPicker }) => <DayPicker {...props} />}</Loader>
}

export default ReactDayPicker
