import styled from 'styled-components'

export const CarSearchContainer = styled('div')`
  object-fit: contain;
  flex-wrap: wrap;
  justify-content: space-between;
  .form-field-container {
    // upper elements will have priority
    &:nth-child(1) {
      z-index: 10;
    }
    &:nth-child(2) {
      z-index: 9;
    }
    &:nth-child(3) {
      z-index: 8;
    }
  }
  .form-title {
    width: 100%;
    height: 40px;
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #525266;
  }
  .form-field {
    z-index: 1;
    &:hover {
      z-index: 2;
    }
    &:focus,
    &.focus {
      z-index: 3;
    }
    &.left input,
    &.left button {
      border-radius: 9px 0 0 9px;
    }
    &.right input,
    &.right button {
      border-radius: 0 9px 9px 0;
      margin-left: -1px;
    }
  }
  .plane {
    fill: white;
  }
  .car-search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    margin-top: 20px;
    background-image: linear-gradient(to left, #ffad5e, #ff7d0e);
    font-size: 12px;
    font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
    border-radius: 0px 12px 12px 0px;
  }
  .button-text {
    margin-left: 5px;
  }
  .loading-animation {
    position: relative;
    left: 38%;
    @media screen and (max-width: 1200px) {
      left: 50%;
    }
  }
`
