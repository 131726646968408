import React from 'react'
import styled from 'styled-components'
import { FlexEl, PageContainer } from '../components/layout'
import { ReactComponent as CallCenterIcon } from '../util/images/call-center-icon.svg'
import { ReactComponent as EmailIcon } from '../util/images/message-in-app.svg'
import NoSSR from '../components/NoSSR'
import { Helmet } from 'react-helmet'
import { themeGet } from 'styled-system'

export const PageWrapper = styled('div')`
  background: #fff;
`

export const PageImage = styled('div')`
  background: url('/images/portal-bg.png') #fff;
  height: calc(100dvh - 80px);
  height: calc(100dvh - 80px);
  width: 75%;
  background-size: 80%;
  position: relative;

  @media (max-width: ${themeGet('breakpoints.1')}px) {
    position: relative;
    width: 100%;
  }
`
export const ContactBox = styled('div')`
  background: #fff;
  position: absolute;
  border-radius: 25px;
  border: 1px solid #d8d8d8;
  background: var(--background-white, #fff);
  box-shadow: 0px 0px 4px 0px #dfdfe7;
  padding: 126px;
  min-width: 800px;
  color: #2d2e2e;
  font-size: 20px;
  font-weight: 500;
  top: 50%;
  right: 172px;
  transform: translate(0, -241px);
  h1 {
    margin: 0;
    font-size: 30px;
    font-weight: 600;
  }
  p {
    margin: 0;
  }
  a {
    color: #2d2e2e;
  }

  @media (max-width: ${themeGet('breakpoints.1')}px) {
    min-width: auto;
    width: 90%;
    padding: 20px;
    transform: initial;
    right: 5%;
    left: 5%;
    top: 40%;
  }
`

const metaDescription =
  "Compare car rental prices from 900 companies in 160 countries. Get car rental offers from major and local suppliers in seconds. Select the best rental car fits your needs. Complete your car reservation under 30 seconds. Whether you're planning a road trip, a family vacation, or a business trip, finding the right rental car is easy with our comprehensive search engine. With a wide selection of cars and rental options to choose from, you'll find the perfect vehicle for your needs. Plus, our streamlined booking process makes it quick and easy to reserve your rental car in just a few clicks."

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us | Carla Car Rental</title>
        <meta name='description' content={metaDescription} />
        <link rel='canonical' href='https://rentcarla.com/contact' />
      </Helmet>
      <NoSSR>
        <PageContainer bg='newColorPrimary'>
          <PageWrapper>
            <PageImage></PageImage>
            <ContactBox>
              <h1>Contact Us</h1>
              <p>Feel free to contact us any time</p>
              <FlexEl
                flexDirection={'column'}
                style={{
                  gap: '20px',
                  marginTop: '50px'
                }}
              >
                <FlexEl
                  flexDirection={'row'}
                  style={{
                    gap: '30px'
                  }}
                >
                  <CallCenterIcon />{' '}
                  <span>
                    <a href='tel:1-415-854-7085'>1-415-854-7085</a>
                  </span>
                </FlexEl>
                <FlexEl
                  flexDirection={'row'}
                  style={{
                    gap: '30px'
                  }}
                >
                  <EmailIcon /> <a href='mailto:carla@rentcarla.com'>carla@rentcarla.com</a>
                </FlexEl>
              </FlexEl>
            </ContactBox>
          </PageWrapper>
        </PageContainer>
      </NoSSR>
    </>
  )
}

export default Contact
