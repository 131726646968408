import React, { useState, useEffect } from 'react'
import util from '../../util'
import { FlexEl } from '../layout'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { SearchBoxForm } from './SearchBoxForm'
import CarCardNew from './../CarCard'
import { connect } from 'react-redux'
import { bool, func, string } from 'prop-types'
import { CarlinPropTypes } from '../../entities'
import SeeOnMap from './SeeOnMap'
import { FilterMenu, MobileFilterMenu } from './Filters'
import { setTagFilters, setCarSearchFilters, setSortingOption } from '../../redux/slices'
import IconCancel from '../../util/images/icon-cancel.png'
import IconClick from '../../util/images/icon-click-grey.png'
import IconArrowDown from '../../util/images/icon-arrow-down.png'
import debug from 'debug'
import debounce from 'lodash/debounce'
import promoCodeLogo from '../../util/images/promo-code-double-icon.svg'
import KayakCarCard from './../KayakCarCard'
import { MobileHeaderBar } from './MobileHeaderBar'
import { OverMobileScreen } from './../../components/common/Screens'

//Styling
import './CarSearchResults.css'
import CarInfoCard from '../../components/CarInfoCard'
import { isEqual } from 'lodash'

const recommendedSort = (a, b) => {
  if (a.extras.score < b.extras.score) {
    return 1
  } else if (b.extras.score < a.extras.score) {
    return -1
  } else {
    return 0
  }
}
const prcLowToHighSort = (a, b) => {
  if (a.price.total < b.price.total) {
    return -1
  } else if (b.price.total < a.price.total) {
    return 1
  } else {
    return 0
  }
}
const prcHighToLowSort = (a, b) => {
  if (a.price.total < b.price.total) {
    return 1
  } else if (b.price.total < a.price.total) {
    return -1
  } else {
    return 0
  }
}

const sortFunctionMap = {
  'Price (low to high)': prcLowToHighSort,
  'Price (high to low)': prcHighToLowSort,
  Recommended: recommendedSort
}

const sortingMap = {
  LH: 'Price (low to high)',
  HL: 'Price (high to low)',
  RECOMMENDED: 'Recommended'
}

const log = debug('carla:order')

// To be removed after rollout is 100/100 on backend tech upgrade
function handleFullyPrepaid(price) {
  if (!price.isFullyPrepaid) return Boolean(price.fullyPrepaid).toString()
  return Boolean(price.isFullyPrepaid).toString()
}

const RawCarSearchResults = styled(
  ({
    carSearchFilters,
    filterTags,
    sortingOption,
    sameLocation,
    pickupLocation,
    dropOffLocation,
    history,
    loading,
    error,
    data,
    searchArgs,
    promoCode,
    showSearchBox,
    searchExpanded,
    onBook,
    onSearchClicked,
    onToggleSearch,
    productHash,
    isNewSearch,
    handleIsNewSearch,
    ...props
  }) => {
    const initialSelectedFilters = {
      carType: [],
      fuel: [],
      transmission: [],
      seats: [],
      cancellationPolicy: [],
      supplier: [],
      broker: [],
      fullyPrepaid: []
    }

    //Check is it new search, if yes clear the filters
    if (isNewSearch) {
      handleIsNewSearch()
      props.setCarSearchFilters(initialSelectedFilters)
      props.setTagFilters([])
    }
    const [filters, setFilters] = useState(initialSelectedFilters)

    //We need a seperate filter tags component because we want the tags to appear in the order when click them.
    //!!!!Filter tags is an array of pairs representing [[category, subcategory]]

    const [renderedCars, setRenderedCars] = useState(<div />)
    //For the numbers next to the subcategories
    const [filterCounts, setFilterCounts] = useState({})
    /* For the show ? cars label in the mobile filter menu
  const [showCarsCount, setShowCarsCount] = useState(0);
  */
    //Controlling whether the header is visible or not
    // eslint-disable-next-line
    const [headerOpen, setHeaderOpen] = useState(true)
    //Controls whether or not the sorting menu is open
    const [isSortingMenuOpen, setIsSortingMenuOpen] = useState(false)

    const [refreshAffirmUiOnce, setRefreshAffirmUiOnce] = useState(false)

    //Used for price per day.
    const howManyDays = util.howManyDays(searchArgs.pickupDateStr, searchArgs.dropOffDateStr)

    //when clicked on the checbox, if the subcategory is on the selected filters remove it, if it is not, add it
    //Do the same for the filter tags
    const selectFilter = (category, subcategory) => {
      let newFilter = carSearchFilters[category].map((e) => e)
      let newFilterTags = filterTags.map((e) => e)

      if (newFilter.includes(subcategory)) {
        //remove element from selectedFilters
        newFilter = newFilter.filter((item) => item !== subcategory)
        //Remove element from filterTags
        newFilterTags = newFilterTags.filter(([itemCat, itemSubCat]) => itemSubCat !== subcategory)
      } else {
        newFilter.push(subcategory)
        newFilterTags.push([category, subcategory])
      }

      let temp = { ...carSearchFilters }

      temp[category] = newFilter
      props.setCarSearchFilters(temp)
      props.setTagFilters(newFilterTags)
    }

    const clearAllFilters = () => {
      props.setCarSearchFilters(initialSelectedFilters)
      props.setTagFilters([])
    }

    const updateTypeDescription = (carDets) => {
      if (carDets.car.classCode && carDets.car.classCode.length > 1) {
        if (carDets.car.classCode[1] === 'P') {
          carDets.car.typeDescription = 'Pickup Truck'
        }
      }
    }

    useEffect(() => {
      const allFilters = {
        carType: [],
        fuel: [],
        transmission: [],
        seats: [],
        cancellationPolicy: [],
        supplier: [],
        broker: [],
        fullyPrepaid: []
      }

      //Get all avaliable filters from the array of cars so that we don't show any unrelevant filters
      if (data) {
        data.cars.map((carDets) => {
          updateTypeDescription(carDets)

          if (!allFilters.carType.includes(carDets.car.typeDescription)) {
            allFilters.carType.sort()
            allFilters.carType.push(carDets.car.typeDescription)
          }
          if (!allFilters.fuel.includes(carDets.car.fuelDescription)) {
            allFilters.fuel.push(carDets.car.fuelDescription)
          }
          if (!allFilters.transmission.includes(carDets.car.transmissionDescription)) {
            allFilters.transmission.push(carDets.car.transmissionDescription)
          }
          if (!allFilters.seats.includes(carDets.car.seatCategory)) {
            allFilters.seats.push(carDets.car.seatCategory)
          }
          if (!allFilters.cancellationPolicy.includes(carDets.extras.cancellationPolicy)) {
            allFilters.cancellationPolicy.push(carDets.extras.cancellationPolicy)
          }
          if (!allFilters.supplier.includes(carDets.supplier.name)) {
            allFilters.supplier.sort()
            allFilters.supplier.push(carDets.supplier.name)
          }
          if (!allFilters.broker.includes(carDets.dependencies.brokerName)) {
            allFilters.broker.sort()
            allFilters.broker.push(carDets.dependencies.brokerName)
          }
          if (!allFilters.fullyPrepaid.includes(handleFullyPrepaid(carDets.price))) {
            allFilters.fullyPrepaid.push(handleFullyPrepaid(carDets.price))
          }
          return 0
        })
        renderCars() //Need an initial render when the data loads
      }
      setFilters(allFilters)
    }, [data, props.adsData])

    const determineFilterCounts = async () => {
      let tempFilterCounts = {}
      //Initialize filters
      for (const category in filters) {
        tempFilterCounts[category] = []
        filters[category].forEach((subcategory) => {
          tempFilterCounts[category][subcategory] = 0
        })
      }
      data.cars.forEach((car) => {
        //For each car, look into each category. We cannot traverse with for since every category information of the car is in a weird place
        //For car type
        let tempSelectedFilters = { ...carSearchFilters }
        tempSelectedFilters.carType = []
        if (isTheCarSelectedInFilters(car, tempSelectedFilters)) {
          tempFilterCounts.carType[car.car.typeDescription] += 1
        }

        //For fuel
        tempSelectedFilters = { ...carSearchFilters }
        tempSelectedFilters.fuel = []
        if (isTheCarSelectedInFilters(car, tempSelectedFilters)) {
          tempFilterCounts.fuel[car.car.fuelDescription] += 1
        }

        //For seats
        tempSelectedFilters = { ...carSearchFilters }
        tempSelectedFilters.seats = []
        if (isTheCarSelectedInFilters(car, tempSelectedFilters)) {
          tempFilterCounts.seats[car.car.seatCategory] += 1
        }

        //For transmission
        tempSelectedFilters = { ...carSearchFilters }
        tempSelectedFilters.transmission = []
        if (isTheCarSelectedInFilters(car, tempSelectedFilters)) {
          tempFilterCounts.transmission[car.car.transmissionDescription] += 1
        }

        //For cancellation policy
        tempSelectedFilters = { ...carSearchFilters }
        tempSelectedFilters.cancellationPolicy = []
        if (isTheCarSelectedInFilters(car, tempSelectedFilters)) {
          tempFilterCounts.cancellationPolicy[car.extras.cancellationPolicy] += 1
        }

        //For supplier
        tempSelectedFilters = { ...carSearchFilters }
        tempSelectedFilters.supplier = []
        if (isTheCarSelectedInFilters(car, tempSelectedFilters)) {
          tempFilterCounts.supplier[car.supplier.name] += 1
        }

        //For broker
        tempSelectedFilters = { ...carSearchFilters }
        tempSelectedFilters.broker = []
        if (isTheCarSelectedInFilters(car, tempSelectedFilters)) {
          tempFilterCounts.broker[car.dependencies.brokerName] += 1
        }

        //For fully prepaid
        tempSelectedFilters = { ...carSearchFilters }
        tempSelectedFilters.fullyPrepaid = []
        if (isTheCarSelectedInFilters(car, tempSelectedFilters)) {
          tempFilterCounts.fullyPrepaid[handleFullyPrepaid(car.price)] += 1
        }
      })
      setFilterCounts(tempFilterCounts)
    }

    const isTheCarSelectedInFilters = (carRentalProduct, carSearchFilters) => {
      const carTypeBool =
        carSearchFilters.carType.length === 0 || carSearchFilters.carType.includes(carRentalProduct.car.typeDescription)
      const fuelBool =
        carSearchFilters.fuel.length === 0 || carSearchFilters.fuel.includes(carRentalProduct.car.fuelDescription)
      const transmissionBool =
        carSearchFilters.transmission.length === 0 ||
        carSearchFilters.transmission.includes(carRentalProduct.car.transmissionDescription)
      const seatsBool =
        carSearchFilters.seats.length === 0 || carSearchFilters.seats.includes(carRentalProduct.car.seatCategory)
      const cancellationPolicyBool =
        carSearchFilters.cancellationPolicy.length === 0 ||
        carSearchFilters.cancellationPolicy.includes(carRentalProduct.extras.cancellationPolicy)
      const supplierBool =
        carSearchFilters.supplier.length === 0 || carSearchFilters.supplier.includes(carRentalProduct.supplier.name)
      const brokerBool =
        carSearchFilters.broker.length === 0 ||
        carSearchFilters.broker.includes(carRentalProduct.dependencies.brokerName)
      const fullyPrepaidBool =
        carSearchFilters.fullyPrepaid.length === 0 ||
        carSearchFilters.fullyPrepaid.includes(handleFullyPrepaid(carRentalProduct.price))
      return (
        carTypeBool &&
        fuelBool &&
        transmissionBool &&
        seatsBool &&
        cancellationPolicyBool &&
        supplierBool &&
        brokerBool &&
        fullyPrepaidBool
      )
    }

    const setCarHeights = (height) => {
      var nodes = document.querySelectorAll('.mobile-card')

      for (var i = 0; i < nodes.length; i++) {
        //nodes[i].style.height = (my_available_height * 4/5).toString().concat("px");
        nodes[i].style.height = height.toString().concat('px')

        if (i === nodes.length - 1) {
          nodes[i].style.marginBottom = '300px'
        }
      }
    }

    const adjustHeights = () => {
      var head_length = 70
      var inner_height = window.innerHeight
      var my_available_height = inner_height - head_length

      var myelements = document.querySelectorAll('.mobile-scroll-container')

      if (inner_height > 900) myelements[0].style.maxHeight = (my_available_height + 165).toString().concat('px')
      else if (inner_height > 800) myelements[0].style.maxHeight = (my_available_height + 140).toString().concat('px')
      else if (inner_height > 700) myelements[0].style.maxHeight = (my_available_height + 130).toString().concat('px')
      else if (inner_height > 600) myelements[0].style.maxHeight = (my_available_height + 115).toString().concat('px')
      else if (inner_height > 500) myelements[0].style.maxHeight = (my_available_height + 100).toString().concat('px')
      else myelements[0].style.maxHeight = (my_available_height + 50).toString().concat('px')

      setCarHeights((my_available_height * 4) / 5)
    }

    useEffect(() => {
      if (data) {
        renderCars()
        determineFilterCounts()
        adjustHeights()
      }
      initUplift()
      refreshAffirm()
    }, [filters, carSearchFilters, sortingOption])

    const onSortingOptionClick = (option) => {
      if (sortingMap[option] !== sortingOption[0]) props.setSortingOption(option)
      setIsSortingMenuOpen(false)
    }

    // TODO: move this function to be a component
    // TODO: reduce number of items
    const renderCars = async () => {
      // data.cars vs adsData -> after sort and before map
      const adsData = props.adsData || []
      const mergeArray = Array.apply(null, Array(data.cars.length + adsData.length))
      adsData.forEach((ads) => (mergeArray[ads.rank] = ads))
      const carsSorted = data.cars.sort(sortFunctionMap[sortingOption[0]])

      let maker = 0
      const mergeCarsWithAds = mergeArray.map((item) => {
        if (item) return item
        const newItem = carsSorted[maker]
        maker++
        return newItem
      })
      const cars = mergeCarsWithAds.map((carRentalProduct, i) => {
        //Check if the cars specs are included in the selected filters. If there isn't anything on the specified selected filters, consider every filter is selected.
        if (carRentalProduct.deepLink && carRentalProduct.impressionUrl) {
          if (
            carSearchFilters.carType.length !== 0 ||
            carSearchFilters.fuel.length !== 0 ||
            carSearchFilters.transmission.length !== 0 ||
            carSearchFilters.seats.length !== 0 ||
            carSearchFilters.cancellationPolicy.length ||
            carSearchFilters.supplier.length !== 0 ||
            carSearchFilters.broker.length !== 0 ||
            carSearchFilters.fullyPrepaid.length !== 0
          ) {
            return null
          }
          return (
            <FlexEl width={1} key={i} justifyContent='center'>
              <KayakCarCard adsProduct={carRentalProduct} />
            </FlexEl>
          )
        }
        const carTypeBool =
          carSearchFilters.carType.length === 0 ||
          carSearchFilters.carType.includes(carRentalProduct.car.typeDescription)
        const fuelBool =
          carSearchFilters.fuel.length === 0 || carSearchFilters.fuel.includes(carRentalProduct.car.fuelDescription)
        const transmissionBool =
          carSearchFilters.transmission.length === 0 ||
          carSearchFilters.transmission.includes(carRentalProduct.car.transmissionDescription)
        const seatsBool =
          carSearchFilters.seats.length === 0 || carSearchFilters.seats.includes(carRentalProduct.car.seatCategory)
        const cancellationPolicyBool =
          carSearchFilters.cancellationPolicy.length === 0 ||
          carSearchFilters.cancellationPolicy.includes(carRentalProduct.extras.cancellationPolicy)
        const supplierBool =
          carSearchFilters.supplier.length === 0 || carSearchFilters.supplier.includes(carRentalProduct.supplier.name)
        const brokerBool =
          carSearchFilters.broker.length === 0 ||
          carSearchFilters.broker.includes(carRentalProduct.dependencies.brokerName)
        const fullyPrepaidBool =
          carSearchFilters.fullyPrepaid.length === 0 ||
          carSearchFilters.fullyPrepaid.includes(handleFullyPrepaid(carRentalProduct.price))

        if (
          carTypeBool &&
          fuelBool &&
          transmissionBool &&
          seatsBool &&
          cancellationPolicyBool &&
          supplierBool &&
          brokerBool &&
          fullyPrepaidBool
        ) {
          return (
            <CarCardNew
              i={i}
              productHash={productHash}
              carRentalProduct={carRentalProduct}
              howManyDays={howManyDays}
              refreshAffirmUiOnce={refreshAffirmUiOnce}
              setRefreshAffirmUiOnce={setRefreshAffirmUiOnce}
              onBook={onBook}
            />
          )
        }
        return null
      })

      setRenderedCars(cars)

      setTimeout(() => {
        refreshAffirm()
        initUplift()
      }, 500)
    }

    useEffect(() => {
      if (!refreshAffirmUiOnce) {
        refreshAffirm()
        initUplift()
        setRefreshAffirmUiOnce(true)
      }
    }, [refreshAffirmUiOnce])

    const refreshAffirm = debounce(
      () => {
        window.affirm.ui.ready(function () {
          window.affirm.ui.refresh()
        })
      },
      500,
      { trailing: true, leading: false }
    )

    const initUplift = debounce(
      () => {
        if (!window.Uplift) {
          ;(function (u, p, l, i, f, t, o, b, j) {
            u['UpLiftPlatformObject'] = f
            /* eslint-disable-next-line */
            ;(u[f] =
              u[f] ||
              function () {
                ;(u[f].q = u[f].q || []).push(arguments)
                /* eslint-disable-next-line */
              }),
              (u[f].l = 1 * new Date())
            /* eslint-disable-next-line */
            ;(b = p.createElement(l)), (j = p.getElementsByTagName(l)[0])
            b.async = 1
            b.src = i + '?id=' + t
            j.parentNode.insertBefore(b, j)
            o = u.location.host.match(/[\w-]+\.\w{2,3}(:\d+)?$/)
            if (o) o = o[0]
            u[f]('create', t, o)
          })(window, document, 'script', 'https://cdn.uplift-platform.com/a/up.js', 'up', util.getUPCode())
        }
        setTimeout(() => {
          if (window.Uplift) setUpReady()
          if (window.upReady) window.upReady()
        }, 300)
      },
      500,
      { trailing: true, leading: false }
    )

    const setUpReady = () => {
      if (window.upReady) log('upReady already exists.')
      if (!window.upReady) log("upReady doesn't exist.")
      window.upReady = () => {
        log('upReady called')
        initPayMonthly()
        window.Uplift.Payments.load(util.getMockDataUpliftLoadObj())
      }
    }

    function initPayMonthly() {
      try {
        window.Uplift.Payments.init({
          apiKey: '4exdzTns3o9Wv6qpkf013aOLw76iwK2TawtzmCvs',
          locale: 'en-US',
          currency: 'USD',
          checkout: false,
          channel: 'desktop'
        })
      } catch (err) {
        log('initPayMonthly->ERR:', err)
      }
    }

    window.upReady = function () {
      try {
        initPayMonthly()
        window.Uplift.Payments.load(util.getMockDataUpliftLoadObj())
      } catch (err) {
        log('window.upReady->ERR:', err)
      }
    }

    const renderFilterTags = () => {
      const removeTag = (filterTag) => {
        //Remove the filter from the tags
        props.setTagFilters(filterTags.filter((item) => !isEqual(item, filterTag)))
        //Remove the filter from the selected filters
        props.setCarSearchFilters({
          ...carSearchFilters,
          [filterTag[0]]: carSearchFilters[filterTag[0]].filter((item) => !isEqual(item, filterTag[1]))
        })
      }

      return filterTags.map((filt) => {
        return (
          <span className='selected-filter-tag' key={filt[1]}>
            <span className='filter-tag-text'>{filt[1]}</span>
            <span className='filter-tag-icons'>
              <img className='filter-tag-icon-click' src={IconClick} onClick={() => removeTag(filt)} alt='click' />
              <img className='filter-tag-icon-cancel' src={IconCancel} onClick={() => removeTag(filt)} alt='cancel' />
            </span>
          </span>
        )
      })
    }

    /*
  const updateShowCarsCount = (tempSelectedFilters) => {
    let total = 0
    data.cars.forEach((car) => {
      if (isTheCarSelectedInFilters(car, tempSelectedFilters)) {
        total++
      }
    })
    setShowCarsCount(total)
  }
  */

    let scrollCarFound = false

    const searchParams = util.getUrlParams(props)
    const { pickupDateStr, dropOffDateStr } = searchParams
    let pickupDate = util.getDateFromAPI(pickupDateStr)
    let dropOffDate = util.getDateFromAPI(dropOffDateStr)
    const timeRange = util.formatDateTimeRange(pickupDate, dropOffDate)

    function ScrollTop() {
      window.scrollTo(0, 0)
    }

    const scrollToTop = () => {
      const scrollTarget = document.getElementById('car-search-results-container')
      if (scrollTarget) {
        scrollTarget.scrollIntoView({ behavior: 'smooth' })
      }
    }

    return (
      <div>
        {error && <div>error</div>}
        {data && (
          <>
            <div className='car-search-results-container' id='car-search-results-container'>
              <div className='left-column-container' onScroll={ScrollTop}>
                {' '}
                {/* Left column container appears only in desktop. In mobile, only empty space.*/}
                {showSearchBox && (
                  <SearchBoxForm
                    onToggleSearch={onToggleSearch}
                    searchExpanded={searchExpanded}
                    onSearchClicked={onSearchClicked}
                  />
                )}
                {/* eslint-disable-next-line no-restricted-globals */}
                {props.location.state && props.location.state.from === 'availableAreas' ? (
                  <div className='show-on-desktop'>
                    <SeeOnMap history={history} />
                  </div>
                ) : null}
                <div className='show-on-desktop'>
                  <FilterMenu
                    filters={filters}
                    selectedFilters={carSearchFilters}
                    selectFilter={selectFilter}
                    clearAllFilters={clearAllFilters}
                    filterCounts={filterCounts}
                  />
                </div>
              </div>
              <div className='right-column-container' onScroll={ScrollTop}>
                <div id='head-part'>
                  {headerOpen && (
                    <div className='mobile-info-card'>
                      <MobileHeaderBar
                        pickupLocationName={pickupLocation?.label}
                        dropOffLocationName={dropOffLocation?.label}
                        timeRange={timeRange}
                      />
                    </div>
                  )}
                </div>
                <OverMobileScreen>
                  <CarInfoCard isBundle={false} />
                </OverMobileScreen>
                <div className='tablet-filter-sort-container'>
                  <MobileFilterMenu
                    filters={filters}
                    selectedFilters={carSearchFilters}
                    setSelectedFilters={props.setCarSearchFilters}
                    clearAllFilters={clearAllFilters}
                    filterTags={filterTags}
                    setFilterTags={props.setTagFilters}
                    filterCounts={filterCounts}
                    scrollToTop={scrollToTop}
                  />
                  <div className='sort-container'>
                    <div className='sort-container-controller' onClick={() => setIsSortingMenuOpen(!isSortingMenuOpen)}>
                      <div className='sort-container-left'>
                        <div className='sort-container-header'>SORT BY</div>
                        <div className='sort-container-main'>{sortingOption[0]}</div>
                      </div>
                      <div className='sort-container-right'>
                        <img
                          className='sort-container-arrow-icon'
                          src={IconArrowDown}
                          style={{
                            transform: isSortingMenuOpen ? 'rotate(180deg)' : 'rotate(0deg)'
                          }}
                          alt='arrow-icon'
                        />
                      </div>
                    </div>
                    <div
                      className='sorting-options-popup'
                      style={
                        isSortingMenuOpen
                          ? {}
                          : {
                              maxHeight: '0',
                              boxShadow: '0 0 0 0 rgba(0,0,0,0)',
                              border: '0px solid white'
                            }
                      }
                    >
                      <div className='sorting-option' onClick={() => onSortingOptionClick('RECOMMENDED')}>
                        Recommended
                      </div>
                      <div className='sorting-option' onClick={() => onSortingOptionClick('LH')}>
                        Price (low to high)
                      </div>
                      <div className='sorting-option' onClick={() => onSortingOptionClick('HL')}>
                        Price (high to low)
                      </div>
                    </div>
                  </div>
                </div>
                <div className='filter-tags-and-sort-menu'>
                  <div className='filter-tags-container'>
                    {renderFilterTags()}
                    {
                      //Render the clear all filters tag if there is any filters
                      filterTags.length > 0 ? (
                        <span className='selected-filter-tag clear-all-tag' onClick={clearAllFilters}>
                          <span className='filter-tag-text'>Clear All Filters</span>
                          <span className='filter-tag-icons'>
                            <img className='filter-tag-icon-cancel-all' src={IconCancel} alt='clear' />
                          </span>
                        </span>
                      ) : null
                    }
                  </div>
                  <div className='desktop-sort-container'>
                    <div className='sort-container'>
                      <div
                        className='sort-container-controller'
                        onClick={() => setIsSortingMenuOpen(!isSortingMenuOpen)}
                      >
                        <div className='sort-container-left'>
                          <div className='sort-container-header'>SORT BY</div>
                          <div className='sort-container-main'>{sortingOption[0]}</div>
                        </div>
                        <div className='sort-container-right'>
                          <img
                            className='sort-container-arrow-icon'
                            src={IconArrowDown}
                            style={{
                              transform: isSortingMenuOpen ? 'rotate(180deg)' : 'rotate(0deg)'
                            }}
                            alt='arrow-icon'
                          />
                        </div>
                      </div>
                      <div
                        className='sorting-options-popup'
                        style={
                          isSortingMenuOpen
                            ? {}
                            : {
                                maxHeight: '0',
                                boxShadow: '0 0 0 0 rgba(0,0,0,0)',
                                border: '0px solid white'
                              }
                        }
                      >
                        <div className='sorting-option' onClick={() => onSortingOptionClick('RECOMMENDED')}>
                          Recommended
                        </div>
                        <div className='sorting-option' onClick={() => onSortingOptionClick('LH')}>
                          Price (low to high)
                        </div>
                        <div className='sorting-option' onClick={() => onSortingOptionClick('HL')}>
                          Price (high to low)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {promoCode && promoCode !== null && promoCode !== '' && (
                  <div className='car-search-promo-code-applied'>
                    <img loading='eager' src={promoCodeLogo} alt='promo_code_applied_icon' />
                    <p style={{ margin: 0 }}>Promo code applied</p>
                  </div>
                )}
                <div className='mobile-scroll-container'>
                  {renderedCars}
                  <div className='mobile-sticky-elements-container'>
                    <MobileFilterMenu
                      filters={filters}
                      selectedFilters={carSearchFilters}
                      setSelectedFilters={props.setCarSearchFilters}
                      clearAllFilters={clearAllFilters}
                      filterTags={filterTags}
                      setFilterTags={props.setFilterTags}
                      filterCounts={filterCounts}
                      setHeaderOpen={setHeaderOpen}
                      scrollToTop={scrollToTop}
                      /*
                    ApplyButtonText={showCarsCount > 100 ? 'Show +100 Cars' : 'Show ' + showCarsCount + ' Cars'}
                    ClearButtonText='Clear All Filters'
                    updateShowCarsCount={updateShowCarsCount}
                  */
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
)`
  .info-card {
    color: ${themeGet('colors.textGrey')};
  }
`

RawCarSearchResults.propTypes = {
  loading: bool.isRequired,
  error: bool,
  data: CarlinPropTypes.CarSearchResponse,
  showSearchBox: bool,
  searchExpanded: bool,
  onBook: func.isRequired,
  onSearchClicked: func.isRequired,
  onToggleSearch: func.isRequired,
  productHash: string
}

export const CarSearchResults = connect(
  (state) => ({
    carSearchFilters: state.carSearchFilters,
    filterTags: state.filterTags,
    sortingOption: state.sortingOption,
    pickupLocation: state.searchArgs.pickupLocation,
    dropOffLocation: state.searchArgs.dropOffLocation,
    sameLocation: state.searchArgs.isSameLocation
  }),
  { setCarSearchFilters, setTagFilters, setSortingOption }
)(RawCarSearchResults)
