import React, { Fragment, useEffect, useState } from 'react'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import { MdSearch } from 'react-icons/md'
import { Logo, MobileLogo } from '../components/svg'
import { El, FlexEl } from '../components/layout'
import BurgerIcon from '../components/BurgerIcon'
import { theme } from '../styles'
import { CarlaContext } from '../App'
import { DesktopScreen, DefaultScreen } from './../components/common/Screens'
import {
  OutlineButton,
  StyledMenu,
  MobileUlTagWrapper,
  NavLink,
  NavbarContainer,
  NavbarWrapper,
  LogoLink,
  NavTagWrapper,
  UlTagWrapper
} from './navigation.styles'
import styled from 'styled-components'
import { useAuth0 } from '@auth0/auth0-react'

const ContactButton = styled('div')`
  border-radius: 5px;
  padding: 14px 25px;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: var(--decorative-surface-orange-gradient, linear-gradient(143deg, #ffab64 -1.15%, #ff7d0e 100%));
`

/**
 * if user is in one of blog pages, show icon or outline button to redirect her to search page
 */
const BlogSearchButton = () => (
  <CarlaContext.Consumer>
    {({ location }) => {
      if (location.pathname.startsWith('/blog')) {
        return (
          <Fragment>
            <El show={[1, 1, 0]} as={RouterLink} to='/' p={'20px 0 10px 10px'} color='colorPrimary'>
              <MdSearch fontSize={32} />
            </El>
            <El show={[0, 0, 1]} pl={15}>
              <OutlineButton as={RouterLink} to='/' px={15}>
                <El as='span' mr='5px'>
                  Search for cars
                </El>
                <MdSearch fontSize={26} />
              </OutlineButton>
            </El>
          </Fragment>
        )
      }
      return <Fragment />
    }}
  </CarlaContext.Consumer>
)

const NavigationBar = ({ currentPage, location, ...props }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const { isAuthenticated, logout, isLoading } = useAuth0()

  useEffect(() => {
    const all = document.getElementsByClassName('nav-link')
    for (let x = 0; x < all.length; x++) {
      const curr = all[x]
      if (curr.pathname === currentPage.pathname) {
        curr.classList.add('active')
      } else {
        curr.classList.remove('active')
      }
    }
  }, [currentPage])

  const toggleActive = (el) => {
    const all = document.getElementsByClassName('nav-link')
    for (let i = 0; i < all.length; i++) {
      if (all[i].pathname === el.currentTarget.pathname) {
        all[i].classList.add('active')
      } else {
        all[i].classList.remove('active')
      }
    }
    setMenuIsOpen(false)
  }

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen)
  }

  const closeMenu = () => {
    setMenuIsOpen(false)
  }

  let hidingLayout = false

  return (
    <>
      <DefaultScreen>
        <header>
          {/* MOBILE COLLAPSING MENU */}
          <StyledMenu
            right
            width='100%' // property for react-burger-menu (not styled)
            isOpen={menuIsOpen}
            pageWrapId='page-container'
            outerContainerId='root'
            customBurgerIcon={false}
            customCrossIcon={false}
          >
            <MobileUlTagWrapper>
              <li>
                <NavLink onClick={toggleActive} className={'nav-link'} py={3} mx={24} as={RouterLink} to='/'>
                  Car Rental
                </NavLink>
              </li>
              {isAuthenticated && (
                <li>
                  <NavLink
                    onClick={toggleActive}
                    className={'nav-link'}
                    py={3}
                    mx={24}
                    as={RouterLink}
                    to='/reservations'
                  >
                    Reservations
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink onClick={toggleActive} className={'nav-link'} py={3} mx={24} as={RouterLink} to='/contact-us'>
                  <ContactButton>Contact Us</ContactButton>
                </NavLink>
              </li>
            </MobileUlTagWrapper>
          </StyledMenu>
          {/* PLACEHOLDER */}
          <El height={theme.navbar.height} />
          {/* FIXED NAVBAR */}
          <NavbarContainer
            id='NAVBAR-HOMEPAGE'
            height={theme.navbar.height}
            px={[15, 25, 15]}
            style={{ boxShadow: '0 2px 6px rgba(0,0,0,.1)', height: '50px' }}
          >
            <NavbarWrapper width={[1, 1, 1120]} justifyContent='space-between' alignItems='center'>
              {/* LOGO */}
              <LogoLink
                to='/'
                as={RouterLink}
                onClick={() => {
                  closeMenu()
                }}
                mr={3}
              >
                <El show={[1, 1, 0]}>
                  <MobileLogo />
                </El>
              </LogoLink>
              {/* NAVBAR RIGHT */}
              {!hidingLayout && (
                <FlexEl id='toggle-button-wrapper' alignItems='center'>
                  {/* BURGER */}
                  <BurgerIcon show={[1, 1, 0]} isOpen={menuIsOpen} onClick={toggleMenu} />
                </FlexEl>
              )}
            </NavbarWrapper>
          </NavbarContainer>
        </header>
      </DefaultScreen>
      <DesktopScreen>
        <header>
          {/* PLACEHOLDER */}
          <El height={theme.navbar.height} />
          {/* FIXED NAVBAR */}
          <NavbarContainer
            id='NAVBAR-HOMEPAGE'
            height={theme.navbar.height}
            px={[15, 25, 15]}
            style={{ boxShadow: '0 2px 6px rgba(0,0,0,.1)' }}
          >
            <NavbarWrapper width={[1, 1, '90%']} justifyContent='space-between' alignItems='center'>
              {/* LOGO */}
              <LogoLink
                to='/'
                {...(!hidingLayout && { as: RouterLink })}
                onClick={() => closeMenu()}
                mr={3}
                style={{ cursor: hidingLayout ? 'initial' : 'pointer' }}
              >
                <El show={[0, 0, 1]}>
                  <Logo />
                </El>
              </LogoLink>
              {/* DESKTOP LINKS */}
              {!hidingLayout && (
                <NavTagWrapper show={[0, 0, 1]} width={1} justifyContent='space-between' px={3}>
                  <UlTagWrapper
                    show={[0, 0, 1]}
                    width={1}
                    justifyContent='flex-start'
                    alignItems='center'
                    px={3}
                  ></UlTagWrapper>
                  <UlTagWrapper show={[0, 0, 1]} width={1} justifyContent='flex-end' alignItems='center' px={3}>
                    {isAuthenticated && (
                      <>
                        <li>
                          <NavLink onClick={toggleActive} className={'nav-link'} py={3} mx={24} as={RouterLink} to='/'>
                            Car Rental
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            onClick={toggleActive}
                            className={'nav-link'}
                            py={3}
                            mx={24}
                            as={RouterLink}
                            to='/reservations'
                          >
                            Reservations
                          </NavLink>
                        </li>
                      </>
                    )}
                    <li>
                      <NavLink
                        onClick={toggleActive}
                        className={'nav-link'}
                        py={3}
                        mx={24}
                        as={RouterLink}
                        to='/contact-us'
                      >
                        <ContactButton>Contact Us</ContactButton>
                      </NavLink>
                    </li>
                    {/* {isAuthenticated && (
                      <li>
                        <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                          Log Out
                        </button>
                      </li>
                    )} */}
                  </UlTagWrapper>
                </NavTagWrapper>
              )}
            </NavbarWrapper>
          </NavbarContainer>
        </header>
      </DesktopScreen>
    </>
  )
}

export default withRouter(NavigationBar)
