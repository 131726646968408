import React, { Component } from 'react'
import Downshift from 'downshift'
import { connect } from 'react-redux'
import debug from 'debug'
import { MenuDS, MenuPlaceholderDS, SelectContainerDS } from '../../CarlaSelect'
import { El, FlexEl } from '../../layout'
import { filterDropOffLocations, formatItem, loadLocationItems } from '../../../util/searchArgs'
import Loading from '../../Loading'
import { Location, Plane } from '../../svg'
import {
  setLocationSearchItems,
  startLocationSearch,
  setSearchPickupLocation,
  setSearchDropOffLocation
} from '../../../redux/slices'
import debounce from 'lodash/debounce'
import trim from 'lodash/trim'
import NoLocationFoundImg from '../../../util/images/icon-lamb.svg'
import LocationIcon from '../../../util/images/icon-location-oneway-pickup.svg'
import {
  LocationIconStyled,
  PickUpLocationWrapper,
  NoLocationFoundImage,
  NoLocationFoundText,
  InputArea,
  GroupTitle,
  PopularLocationsTitle
} from './LocationFiled.styles'

const log = debug('carla:search')

// TODO: refactor later
class LocationComboBox extends Component {
  state = {
    current: null, // current focused field of location range (from|to|null)
    inputValue: '',
    popularLocations: null,
    showNoLocationFound: true,
    location: ''
  }

  isFocused = (key) => this.state.current === key // effects SelectDS

  handleInputFocus = (key) => (e) => {
    this.setState({ current: key, inputValue: '' })
  }
  handleInputBlur = (e) => {
    this.setState({ current: null, inputValue: '' })
  }

  handleLocationChange = async (val) => {
    const { type, setSearchPickupLocation, setSearchDropOffLocation } = this.props
    if (type === 'pickUp') {
      setSearchPickupLocation(val)
    } else {
      setSearchDropOffLocation(val)
    }

    this.setState({ current: null, inputValue: '' })
    this.pickupInput.blur()
    if (this.dropOffInput) this.dropOffInput.blur()
  }

  // @TODO DRY
  handleInputChange = (e) => {
    this.setState({ showNoLocationFound: false })
    const inputValue = e.target.value
    this.setState({ inputValue })
    const input = trim(inputValue)

    this.requestAPI(input)
  }

  // call item loading functions with 300ms delay / @TODO DRY
  requestAPI = debounce(
    (input) => {
      // TODO: react-query
      if (!input || !input.trim()) return
      this.props.startLocationSearch()
      loadLocationItems(input)
        .then((items) => {
          if (items && items.length > 0) {
            this.props.setLocationSearchItems(items)
          } else {
            log('no location found', items)
            this.props.setLocationSearchItems([])
          }
        })
        .catch((err) => {
          log('error during car search', err)
          this.props.setLocationSearchItems([])
        })

      this.setState({ showNoLocationFound: true })
    },
    500,
    { trailing: true, leading: false }
  )

  render() {
    const {
      locationsSearch,
      locationChange,
      pickupLocation,
      dropOffLocation,
      optimize,
      sameLocationField,
      isCarSearchPage,
      label,
      type,
      leftRadius = 0,
      rightRadius = 0,
      popularLocations,
      ...rest
    } = this.props
    const { items, isLoading, error } = locationsSearch
    const filteredItems = this.isFocused('to') ? filterDropOffLocations(items) : items
    const isOpen = Boolean(this.state.current)

    return (
      <Downshift
        onChange={this.handleLocationChange}
        onSelect={this.handleLocationChange}
        items={filteredItems}
        itemToString={(item) => (item ? item.label : '')}
        isOpen={isOpen}
        {...rest}
      >
        {({ getRootProps, getMenuProps, getItemProps, getInputProps }) => {
          let inputValue = type === 'pickUp' ? pickupLocation?.label : dropOffLocation?.label

          if (this.isFocused('to')) {
            inputValue = this.state.inputValue
          }

          const pickupFormClass = `form-field left ${this.isFocused('from') ? 'focus' : ''}`

          return (
            <SelectContainerDS {...getRootProps()}>
              <div className='location-form-container'>
                <FlexEl flexDirection='column' width={1}>
                  <El width={1} className={pickupFormClass}>
                    <GroupTitle>{label}</GroupTitle>
                    <PickUpLocationWrapper
                      $leftRadius={leftRadius}
                      $rightRadius={rightRadius}
                      shadowEffect={this.isFocused('from')}
                    >
                      <LocationIconStyled src={LocationIcon} alt='location icon' />
                      <InputArea
                        {...getInputProps()}
                        placeholder='Enter city or airport'
                        value={inputValue}
                        innerRef={(node) => (this.pickupInput = node)}
                        onFocus={this.handleInputFocus('to')}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputBlur}
                      />
                    </PickUpLocationWrapper>
                  </El>
                </FlexEl>
              </div>
              {isOpen && (
                <MenuDS
                  isOpen={isOpen}
                  style={{ position: 'absolute', overflow: 'auto', width: '100%' }}
                  as='ul'
                  className='items'
                  {...getMenuProps()}
                >
                  {isLoading && (
                    <MenuPlaceholderDS>
                      <Loading />
                    </MenuPlaceholderDS>
                  )}
                  {this.state.showNoLocationFound &&
                    (!isLoading && !filteredItems && (error || (this.state.inputValue && !items)) ? (
                      <MenuPlaceholderDS>
                        <NoLocationFoundImage src={NoLocationFoundImg} />
                        <NoLocationFoundText>{"We couldn't find the location"}</NoLocationFoundText>
                        <NoLocationFoundText>{'you are searching. Try entering'}</NoLocationFoundText>
                        <NoLocationFoundText>{'a city or an airport.'}</NoLocationFoundText>
                      </MenuPlaceholderDS>
                    ) : !isLoading && filteredItems ? (
                      filteredItems.map((item, index) => (
                        <li key={index} className='item' {...getItemProps({ key: item.value, item, index })}>
                          <FlexEl justifyContent='flex-start' style={{ gap: '10px' }}>
                            <El>{item.type === 'airport' ? <Plane /> : <Location />}</El>
                            <El>{!!item && formatItem(item, this.state.inputValue)}</El>
                          </FlexEl>
                        </li>
                      ))
                    ) : (
                      popularLocations &&
                      !isLoading && (
                        <div>
                          <PopularLocationsTitle>Popular Locations</PopularLocationsTitle>
                          {popularLocations.map((item, index) => (
                            <li
                              key={index}
                              className='item'
                              {...getItemProps({
                                key: item.value,
                                item,
                                index
                              })}
                            >
                              <FlexEl justifyContent='flex-start' style={{ gap: '10px' }}>
                                <El>{item.type === 'airport' ? <Plane /> : <Location />}</El>
                                <El>{formatItem(item, this.state.inputValue)}</El>
                              </FlexEl>
                            </li>
                          ))}
                        </div>
                      )
                    ))}
                </MenuDS>
              )}
            </SelectContainerDS>
          )
        }}
      </Downshift>
    )
  }
}

const LocationField = connect(
  (state) => ({
    locationsSearch: state.locationSearch,
    pickupLocation: state.searchArgs.pickupLocation,
    dropOffLocation: state.searchArgs.dropOffLocation
  }),
  {
    startLocationSearch,
    setLocationSearchItems,
    setSearchPickupLocation,
    setSearchDropOffLocation
  }
)(LocationComboBox)

export default LocationField
